import dayjs from "dayjs";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Row, Col, Card, CardBody } from "react-bootstrap";
import {
  dueDayDataRequest,
  getCardDataRequest,
  getPurchsePaymentCardDataRequest,
} from "../../store/dashboard/dashboard.action";
import { dashboardSelector } from "../../store/dashboard/dashboard.model";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { orderSelector } from "../../store/order/order.model";
import {
  creditDueDataRequest,
  getPaymentRequest,
  getStatPaymentDataRequest,
  getUserOrderDataRequest,
  UpdateOrderStatusRequest,
} from "../../store/order/order.action";
import { createOrderPayload } from "../../utils/config";
import {
  paymentTypewithcheck,
  statusClasses,
  statusData,
} from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import { companySelector } from "../../store/PurchaseCompany/pc.model";
import { getPurchasePaymentRequest } from "../../store/PurchaseOrder/po.action";
import { purchaseOrderSelector } from "../../store/PurchaseOrder/po.model";

function DashboardPayment() {
  // Selector
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getPaymentList, creditDueData, getPaymentStatData } =
    useSelector(orderSelector);
  const { cardData, dueDayData, purchasePaymentcardData } =
    useSelector(dashboardSelector);

  const { companyData } = useSelector(companySelector);

  console.log(dueDayData, "6789");
  console.log(creditDueData, "creditDueData");
  console.log(getPaymentList, "getPaymentList");
  const { purchasePayment } = useSelector(purchaseOrderSelector);
  console.log(getPaymentStatData, "purchasePayment");
  console.log(purchasePaymentcardData, "poiuy");

  //   state
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [value, setValues] = useState(null);
  const [todayOrder, setTodayOrder] = useState();
  const [todayDate, setToday] = useState();

  const [monthTotal, setMonthTotal] = useState(0);
  const [totToday, settotToday] = useState(0);
  const [monthTotalPurchase, setMonthTotalPurchase] = useState(0);
  const [totTodayPurchase, settotTodayPurchase] = useState(0);



  //   function

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  //   console.log(date,typeof(date),'asdf');
  console.log(companyData, "asdf");
  useEffect(() => {
    const today = new Date();
    const date = dayjs(today).format("YYYY-MM-DD");
    dispatch(
      getPaymentRequest({
        query: searchQuery,
        page: page,
        limit: rowsPerPage,
        from_date: date,
        to_date: date,
      })
    );
    dispatch(getStatPaymentDataRequest());
    dispatch(
      getPurchasePaymentRequest({
        query: searchQuery,
        page: page,
        limit: rowsPerPage,
        from_date: date,
        to_date: date,
      })
    );
    dispatch(getPurchsePaymentCardDataRequest());
  }, [searchQuery]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Payment Dashboard</h2>
              <p>Payment data about your business here</p>
            </div>
          </div>
          <div>
            <h3>Order Payment</h3>
            <Row className="">
              <Col lg={3}>
                <div className="card card-body mb-4">
                  <article className="icontext">
                    <span className="icon icon-sm rounded-circle bg-success-light">
                      <i class="text-success material-icons md-monetization_on"></i>
                      {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                    </span>
                    <div className="text">
                      <h6 className="mb-1 card-title">
                        Total Order Payment Amount
                      </h6>
                      <span>
                        {purchasePaymentcardData?.orders?.totalPayment}
                      </span>
                    </div>
                  </article>
                </div>
              </Col>
              <Col lg={3}>
                <div className="card card-body mb-4">
                  <article className="icontext">
                    <span className="icon icon-sm rounded-circle bg-warning-light">
                      <i class="text-warning material-icons md-monetization_on"></i>
                      {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                    </span>
                    <div className="text">
                      <h6 className="mb-1 card-title">
                        Order Payment Amount Today
                      </h6>
                      <span>
                        {purchasePaymentcardData?.orders?.paymentToday}
                      </span>
                    </div>
                  </article>
                </div>
              </Col>
              <Col lg={3}>
                <div className="card card-body mb-4">
                  <article className="icontext">
                    <span className="icon icon-sm rounded-circle bg-info-light">
                      <i class="text-info material-icons md-monetization_on"></i>
                      {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                    </span>
                    <div className="text">
                      <h6 className="mb-1 card-title">
                        Outstanding Order Payment
                      </h6>
                      <span>
                        {purchasePaymentcardData?.orders?.outstandingPayment}
                      </span>
                    </div>
                  </article>
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <h3>Purchase Payment</h3>
            <Row className="">
              <Col lg={3}>
                <div className="card card-body mb-4">
                  <article className="icontext">
                    <span className="icon icon-sm rounded-circle bg-success-light">
                      <i class="text-success material-icons md-monetization_on"></i>
                      {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                    </span>
                    <div className="text">
                      <h6 className="mb-1 card-title">
                        Total Purchase Payment Amount
                      </h6>
                      <span>
                        {purchasePaymentcardData?.purchases?.totalPayment}
                      </span>
                    </div>
                  </article>
                </div>
              </Col>
              <Col lg={3}>
                <div className="card card-body mb-4">
                  <article className="icontext">
                    <span className="icon icon-sm rounded-circle bg-warning-light">
                      <i class="text-warning material-icons md-monetization_on"></i>
                      {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                    </span>
                    <div className="text">
                      <h6 className="mb-1 card-title">
                        Purchase Payment Amount Today
                      </h6>
                      <span>
                        {purchasePaymentcardData?.purchases?.paymentToday}
                      </span>
                    </div>
                  </article>
                </div>
              </Col>
              <Col lg={3}>
                <div className="card card-body mb-4">
                  <article className="icontext">
                    <span className="icon icon-sm rounded-circle bg-info-light">
                      <i class="text-info material-icons md-monetization_on"></i>
                      {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                    </span>
                    <div className="text">
                      <h6 className="mb-1 card-title">
                        Outstanding Purchase Payment
                      </h6>
                      <span>
                        {purchasePaymentcardData?.purchases?.outstandingPayment}
                      </span>
                    </div>
                  </article>
                </div>
              </Col>
              {/* <Col md="3">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-1 overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Payment Total This Month
                      </p>
                      <h4 className="mb-0">{monthTotal}</h4>
                    </div>
                    <div className="text-primary">
                      <i className={"ri-funds-line  font-size-24"}></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            </Row>
          </div>

          <div className="card">
            <div className="card-header">
              <h4>Order Payment List</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Receipt No</th>
                      <th>Vendor</th>
                      <th>Transaction Type</th>
                      <th>Payment Date</th>
                      <th>Pay Amount</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getPaymentList?.data?.map((ele, ind) => {
                      return (
                        <tr>
                          <td>{ele.id}</td>
                          <td>{ele.user?.name}</td>
                          <td>
                            {ele?.payment_type}
                            {
                              paymentTypewithcheck.find(
                                (ele) => ele?.id == ele?.payment_type
                              )?.mode
                            }
                          </td>
                          <td>
                            {" "}
                            {ele?.payment_date &&
                              dayjs(ele?.payment_date).format("YYYY-MM-DD")}
                          </td>
                          <td>{ele.pay_amt}</td>
                          <td>{ele?.notes ?? "-"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <h4>Purchase Payment List</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Receipt No</th>
                      <th>Company Name</th>
                      <th>Transaction Type</th>
                      <th>Payment Date</th>
                      <th>Pay Amount</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchasePayment?.data?.map((ele, ind) => {
                      return (
                        <tr>
                          <td>{ele.id}</td>
                          <td>{ele?.purchasecompany?.company_name}</td>
                          <td>
                            {ele?.payment_type === 0 ? "Cash" : "Bank"}
                            {/* {
                              paymentTypewithcheck.find(
                                (ele) => ele?.id == ele?.payment_type
                              )?.mode
                            }    */}
                          </td>
                          <td>
                            {" "}
                            {ele?.payment_date &&
                              dayjs(ele?.payment_date).format("YYYY-MM-DD")}
                          </td>
                          <td>{ele.pay_amt}</td>
                          <td>{ele?.notes ?? "-"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default DashboardPayment;
