import alertmessage from "../../utils/alert";
import { decryptdata } from "../../utils/encrypt&decrypt";
import { orderInitialState, orderActionTypes } from "./order.model";

const reducer = (state = orderInitialState, action) => {
  switch (action.type) {
    case orderActionTypes.GETUSERORDER_REQUEST:
      return {
        ...state,
        userOrderLoading: true,
      };
    case orderActionTypes.GETUSERORDER_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas3 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas3, "yhoioasd");
      return {
        ...state,
        userOrderLoading: false,
        userOrderData: payloaddatas3,
      };
    case orderActionTypes.GETUSERORDER_FAILURE:
      return {
        ...state,
        userOrderLoading: false,
      };

    case orderActionTypes.GETUSERCOTATION_REQUEST:
      return {
        ...state,
        userCotationLoading: true,
      };
    case orderActionTypes.GETUSERCOTATION_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas31 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas31, "yhoioasd");
      return {
        ...state,

        userCotationLoading: false,
        userCotationData: payloaddatas31,
      };
    case orderActionTypes.GETUSERCOTATION_FAILURE:
      return {
        ...state,
        userCotationLoading: false,
      };

      case orderActionTypes.GETUSERCOTATIONDETAILS_REQUEST:
        return {
          ...state,
          quotationLoading: true,
        };
      case orderActionTypes.GETUSERCOTATIONDETAILS_SUCCESS:
        console.log(action?.payload, process.env.SECRET_KEY, "dgbsf78");
        let payloaddatas313 = JSON.parse(
          decryptdata(
            action?.payload?.data?.datas,
            "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
          )
        );
        console.log(payloaddatas313, "yhoioasd");
        return {
          ...state,
  
        
          quotationDetails:payloaddatas313,
          quotationLoading:false,
        };
      case orderActionTypes.GETUSERCOTATIONDETAILS_FAILURE:
        return {
          ...state,
          quotationLoading: false,
        };

    case orderActionTypes.GETALLUSERORDER_REQUEST:
      return {
        ...state,
        userAllOrderLoading: true,
      };
    case orderActionTypes.GETALLUSERORDER_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas4 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas4, "yhoioasd");
      return {
        ...state,
        userAllOrderLoading: false,
        userAllOrderData: payloaddatas4?.data,
      };
    case orderActionTypes.GETALLUSERORDER_FAILURE:
      return {
        ...state,
        userAllOrderLoading: false,
      };

    case orderActionTypes.POSTUSERCOTATION_REQUEST:
      return {
        ...state,
        postCotationLoading: true,
        postCotationSuccess: false,
      };
    case orderActionTypes.POSTUSERCOTATION_SUCCESS:
      return {
        ...state,
        postCotationLoading: false,
        postCotationSuccess: true,
      };
    case orderActionTypes.POSTUSERCOTATION_FAILURE:
      return {
        ...state,
        postCotationLoading: false,
        postCotationSuccess: false,
      };

    case orderActionTypes.ORDERDISPATCH_REQUEST:
      return {
        ...state,
        postProLoading: true,
        productSendSuccess: false,
      };
    case orderActionTypes.ORDERDISPATCH_SUCCESS:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: true,
      };
    case orderActionTypes.ORDERDISPATCH_FAILURE:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: false,
      };

    case orderActionTypes.ORDERDETAILBYID_REQUEST:
      return {
        ...state,
        orderDetailLoad: true,
      };
    case orderActionTypes.ORDERDETAILBYID_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmlss");
      let payloaddatas = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas, "mju");

      return {
        ...state,
        orderDetailLoad: false,
        orderDetailData: payloaddatas?.response,
      };
    case orderActionTypes.ORDERDETAILBYID_FAILURE:
      if (
        action.payload.response.data.message ===
        "Your session expired! Please login to continue!"
      ) {
        alertmessage.sweetalert(action.payload.response.data);
      }
      return {
        ...state,
        orderDetailLoad: false,
      };

    case orderActionTypes.ORDERDSTATUSUPDADE_REQUEST:
      return {
        ...state,
        updateStatusLoad: true,
        updateStatusSuccess: false,
      };
    case orderActionTypes.ORDERDSTATUSUPDADE_SUCCESS:
      return {
        ...state,
        updateStatusLoad: false,
        updateStatusSuccess: true,
      };
    case orderActionTypes.ORDERDSTATUSUPDADE_FAILURE:
      return {
        ...state,
        updateStatusLoad: false,
        updateStatusSuccess: false,
      };

      case orderActionTypes.ORDERDBILLTYPEUPDADE_REQUEST:
      return {
        ...state,
        billTypeLoading:true,
        billTypeSuccess:false
      };
    case orderActionTypes.ORDERDBILLTYPEUPDADE_SUCCESS:
      return {
        ...state,
        billTypeLoading:false,
        billTypeSuccess:true
      };
    case orderActionTypes.ORDERDBILLTYPEUPDADE_FAILURE:
      return {
        ...state,
        billTypeLoading:false,
        billTypeSuccess:false
      };
    case orderActionTypes.CREDITDUEPAYDATA_REQUEST:
      return {
        ...state,
        creditPayLoad: true,
        updateStatusSuccess: false,
      };
    case orderActionTypes.CREDITDUEPAYDATA_SUCCESS:
      return {
        ...state,
        creditPayLoad: false,
        creditDueData: action?.payload?.data?.data,
      };
    case orderActionTypes.CREDITDUEPAYDATA_FAILURE:
      console.log(action.payload.response.data.message, "jhfksf34");
      if (
        action.payload.response.data.message ===
        "Your session expired! Please login to continue!"
      ) {
        alertmessage.sweetalert(action.payload.response.data.message);
      }

      return {
        ...state,
        sreditPayLoad: false,
      };

    case orderActionTypes.SREDITDUEPAYDATA_REQUEST:
      return {
        ...state,
        sreditPayLoad: true,
        updateStatusSuccess: false,
      };
    case orderActionTypes.SREDITDUEPAYDATA_SUCCESS:
      console.log(action?.payload?.data?.data, "creditDueData");
      return {
        ...state,
        sreditPayLoad: false,
        sreditDueData: action?.payload?.data?.data,
      };

    case orderActionTypes.SREDITDUEPAYDATA_FAILURE:
      console.log(action.payload.response.data.message, "jhfksf34");
      if (
        action.payload.response.data.message ===
        "Your session expired! Please login to continue!"
      ) {
        alertmessage.sweetalert(action.payload.response.data.message);
      }
      return {
        ...state,
        sreditPayLoad: false,
      };

    case orderActionTypes.GENERATE_REQUEST:
      return {
        ...state,
        pdfLoad: true,
        getpdfDataSuccess: false,
      };
    case orderActionTypes.GENERATE_SUCCESS:
      return {
        ...state,
        pdfLoad: false,
        pdfSendsuccess: action?.payload?.data?.file_name,
        getpdfDataSuccess: true,
      };
    case orderActionTypes.GENERATE_FAILURE:
      return {
        ...state,
        pdfLoad: false,
        getpdfDataSuccess: false,
      };
    case orderActionTypes.GENERATEE_REQUEST:
      return {
        ...state,
        pdfELoad: true,
        getEpdfDataSuccess: false,
      };
    case orderActionTypes.GENERATEE_SUCCESS:
      return {
        ...state,
        pdfELoad: false,
        pdfESendsuccess: action?.payload?.data?.file_name,
        getEpdfDataSuccess: true,
      };
    case orderActionTypes.GENERATEE_FAILURE:
      return {
        ...state,
        pdfELoad: false,
        getEpdfDataSuccess: false,
      };
    case orderActionTypes.CHECKOUT_POST_REQUEST:
      return {
        ...state,
        checkOutLoad: true,
      };
    case orderActionTypes.CHECKOUT_POST_SUCCESS:
      return {
        ...state,
        checkOutLoad: false,
        succesMessage: action?.payload?.data?.message,
      };
    case orderActionTypes.CHECKOUT_POST_FAILURE:
      return {
        ...state,
        checkOutLoad: false,
      };

      case orderActionTypes.UPDATE_POST_REQUEST:
      return {
        ...state,
        updateLoad: true,
      };
    case orderActionTypes.UPDATE_POST_SUCCESS:
      return {
        ...state,
        updateLoad: false,
        updateMessage: action?.payload?.data?.message,
      };
    case orderActionTypes.UPDATE_POST_FAILURE:
      return {
        ...state,
        updateLoad: false,
      };

    case orderActionTypes.GETORDERDATABYID_REQUEST:
      return {
        ...state,
        getOrderbyUserDetailsloading: true,
      };
    case orderActionTypes.GETORDERDATABYID_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmlss");
      let payloaddatas1 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas1, "thisis the");
      return {
        ...state,
        getOrderbyUserDetailsloading: false,
        userOrderDetailsById: payloaddatas1,
      };
    case orderActionTypes.GETORDERDATABYID_FAILURE:
      if (
        action.payload.response.data.message ===
        "Your session expired! Please login to continue!"
      ) {
        alertmessage.sweetalert(action.payload.response.data);
      }
      return {
        ...state,
        getOrderbyUserDetailsloading: false,
      };
    case orderActionTypes.ORDERPAYMENTSEND_REQUEST:
      return {
        ...state,
        paymentLoad: true,
        paymentSuccess: false,
      };
    case orderActionTypes.ORDERPAYMENTSEND_SUCCESS:
      return {
        ...state,
        paymentLoad: false,
        paymentSuccess: true,
      };
    case orderActionTypes.ORDERPAYMENTSEND_FAILURE:
      return {
        ...state,
        paymentLoad: false,
        paymentSuccess: false,
      };

    case orderActionTypes.GETPAYMENTLIST_REQUEST:
      return {
        ...state,
        getPaymentLoad: true,
      };
    case orderActionTypes.GETPAYMENTLIST_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmlss");
      let paymentData = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(paymentData, "thisis the");
      return {
        ...state,
        getPaymentLoad: false,
        getPaymentList: paymentData,
      };
    case orderActionTypes.GETPAYMENTLIST_FAILURE:
      if (
        action.payload.response.data.message ===
        "Your session expired! Please login to continue!"
      ) {
        alertmessage.sweetalert(action.payload.response.data);
      }
      return {
        ...state,
        getPaymentLoad: false,
      };
    case orderActionTypes.GETPAYMENTSTATDATA_REQUEST:
      return {
        ...state,
        getPaymentStatLoad: true,
      };
    case orderActionTypes.GETPAYMENTSTATDATA_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmlss");
      let paymentStatData = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(paymentStatData, "thisis the");
      return {
        ...state,
        getPaymentStatLoad: false,
        getPaymentStatData: paymentStatData,
      };
    case orderActionTypes.GETPAYMENTSTATDATA_FAILURE:
      if (
        action.payload.response.data.message ===
        "Your session expired! Please login to continue!"
      ) {
        alertmessage.sweetalert(action.payload.response.data);
      }
      return {
        ...state,
        getPaymentStatLoad: false,
      };
    case orderActionTypes.UPDATETRANSPORTPRICE_REQUEST:
      return {
        ...state,
        TpriceLoading: true,
        TpriceSuccess: false,
      };
    case orderActionTypes.UPDATETRANSPORTPRICE_SUCCESS:
      return {
        ...state,
        TpriceLoading: false,
        TpriceSuccess: true,
      };
    case orderActionTypes.UPDATETRANSPORTPRICE_FAILURE:
      return {
        ...state,
        TpriceLoading: false,
        TpriceSuccess: false,
      };
    case orderActionTypes.WEEKLYPAYDATAGET_REQUEST:
      return {
        ...state,
        weeklyPayLoad: true,
      };
    case orderActionTypes.WEEKLYPAYDATAGET_SUCCESS:
      return {
        ...state,
        weeklyPayLoad: false,
        weeklypayLoadData: action.payload?.data?.data,
      };
    case orderActionTypes.WEEKLYPAYDATAGET_FAILURE:
      if (
        action.payload.response.data.message ===
        "Your session expired! Please login to continue!"
      ) {
        alertmessage.sweetalert(action.payload.response.data);
      }
      return {
        ...state,
        weeklyPayLoad: false,
      };
    case orderActionTypes.ORDERSTATE_RESET_REQUEST:
      console.log("Cleaner");

      return {
        userOrderLoading: false,
        userOrderData: [],
        userCotationLoading: false,
        userCotationData: [],
        userAllOrderLoading: false,
        userAllOrderData: [],
        orderDisLoad: false,
        orderDetailLoad: false,
        orderDetailData: [],
        updateStatusLoad: false,
        updateStatusSuccess: false,
        creditPayLoad:false,
        creditDueData:[],
        sreditPayLoad:false,
        sreditDueData:[],
        pdfLoad:false,
        pdfSendsuccess:"",
        getpdfDataSuccess:false,
        pdfELoad:false,
        pdfESendsuccess:"",
        getEpdfDataSuccess:false,
        succesMessage: "",
        checkOutLoad: false,
        getOrderbyUserDetailsloading:false,
        userOrderDetailsById:[],
        paymentLoad:false,
        paymentSuccess:false,
        getPaymentLoad:false,
        getPaymentList:[],
        getPaymentStatLoad:false,
        getPaymentStatData:[],
        TpriceLoading:false,
        TpriceSuccess:false,
        weeklyPayLoad:false,
        weeklypayLoadData:[],
        postCotationLoading:false,
        postCotationSuccess:false,
        quotationDetails:[],
        quotationLoading:false,
        billTypeLoading:false,
        billTypeSuccess:false,
        updateMessage: "",
        updateLoad: false,
      };
    default:
      return state;
  }
};
export { reducer as orderReducer };
