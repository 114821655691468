import React, { useEffect } from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import Select from "react-dropdown-select";
import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  statusData,
  orderStatusData,
  statusClasses,
} from "../../utils/Constants";
import { orderSelector } from "../../store/order/order.model";
import {
    getUserCotationDataRequest,
  getUserOrderDataRequest,
  orderStateResetRequest,
  postUserCotationDataRequest,
  UpdateOrderStatusRequest,
  updateTpriceRequest,
} from "../../store/order/order.action";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { getUserDataRequest, userSelector } from "../../store/user/user.action";
import { useForm } from "react-hook-form";
import { createOrderPayload } from "../../utils/config";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { authSelector } from "../../store/auth/auth.action";

function PageCotation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* Selector */
  const { userOrderData, updateStatusSuccess, postCotationSuccess,userCotationData } =
    useSelector(orderSelector);
  const { userData,screenData } = useSelector(userSelector);
  console.log(userData, "userDAa");
  console.log(userOrderData, updateStatusSuccess, "ssdfe54");
  const { afterToday } = DateRangePicker;
  // const { screen } = useSelector(authSelector);
  console.log(userCotationData,'userCotationData');
  
  // const {userData } = useSelector(userSelector);
  // const screen = Array.isArray(userData?.data) && userData?.data[0]?.screen || {};
  const screen = screenData?.response?.screen || {};
  /* useFormHook */
  const { register, handleSubmit, setValue, reset } = useForm();
  /* State */
  // const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [transport, setTransport] = useState("");
  const [value, setValues] = useState(null);
  const [show, setShow] = useState(false);
  const [sel_id, setSelId] = useState(false);

  const startIndex = (page - 1) * rowsPerPage;
  let endIndex = [page] * rowsPerPage;
  if (endIndex > userCotationData?.count) {
    endIndex = userCotationData?.count;
  }

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setSelId(id);
  };
const changeOrder=(id)=>{
    const data=userCotationData.find((ele)=>ele.id==id)
    console.log(data,'hdfjfdkaj');
    let payload={
        total_price:data.total_price,
        status:1,
        tax_price:data.tax_price,
        sub_total:data.sub_total,
        order_status:2,
        type:0,
        // payment_status:
        // payment_balance:
        // transport_charge:
        user_id:data.user_id,
        user:data.user,
        cartData:data.cotationdetails,
        cotation_no:data.cotation_no

    }
    dispatch(postUserCotationDataRequest(payload))
    
}
  /* Function */
  console.log(userCotationData,'dkslj');
  
  const totalPages1 = Math.ceil(userCotationData?.length / rowsPerPage)|| 1;

  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1 && currentPage > 3
        ? currentPage + 2
        : null
      : null;
  const lastPage_before =
    currentPage > 3 && totalPages1 != currentPage ? "..." : null;
  const lastPage =
    currentPage > 3 && totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleAddCharges = () => {
    console.log(transport, sel_id, "h");
    let payload = {
      payload: { transport_charge: transport },

      id: sel_id,
    };
    dispatch(updateTpriceRequest(payload));
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const onStatusChanges = (eve, id) => {
    console.log(eve, id, "evvid");
    const payload = {
      id,
      status: eve,
    };
    dispatch(UpdateOrderStatusRequest(payload));
  };
  const onPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages1) {
      setPage(newPage);
    }
  };

  const filterSubmit = (formData) => {
    formData.from_date = value?.[0] ?? "";
    formData.to_date = value?.[1] ?? "";
    console.log(formData, "56dfd78");
    const payload = createOrderPayload(
      page,
      rowsPerPage,
      "",
      "",
      formData?.customer,
      formData?.order_status,
      formData?.order_id,
      formData?.from_date,
      formData?.to_date
    );
    dispatch(getUserOrderDataRequest(payload));
    reset();
    setValues(null);
  };

  /* UseState */
  useEffect(()=>{
    const payload = createOrderPayload(
        page,
        rowsPerPage,
        searchQuery,
        statusFilter
      );

    dispatch(getUserCotationDataRequest(payload))
  },[page,rowsPerPage,searchQuery,statusFilter])
  useEffect(()=>{
    const payload = createOrderPayload(
        page,
        rowsPerPage,
        searchQuery,
        statusFilter
      );

    dispatch(getUserCotationDataRequest(payload))
  },[postCotationSuccess])
 
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Quotation List</h2>
            </div>
            {/* <div>
              {screen?.["order-add"] && 
              <button
                className="btn btn-primary"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/pageorders/add");
                  // dispatch(pcStateResetRequest());
                }}
              >
                <i className="material-icons md-plus"></i> Create new
              </button>
}
            </div> */}
          </div>
          <Row>
            <Col md={12}>
              <div className="card mb-4">
                <header className="card-header">
                  <Row className="gx-3">
                    <Col lg={4} md={6} className="me-auto">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </Col>
                    <Col lg={2} md={3} className="col-6">
                    <span className="font-md color-gray-900 font-medium border-1-right span">
                      <h6>
                        {" "}
                      {/* {userCotationData?.count>endIndex ? `Showing ${startIndex}- ${endIndex} of ${userCotationData?.count} result`:`Showing ${startIndex}- ${userCotationData?.count} of ${userCotationData?.count} result`} */}

                        Showing {startIndex}&ndash;{endIndex} <br /> of{" "}
                        {userCotationData?.count} results
                      </h6>
                    </span>
                    </Col>
                    <Col lg={2} md={3} sm={6}>
                      <select
                        className="form-select"
                        onChange={(e) => setRowsPerPage(e.target.value)}
                      >
                        <option value={10}>Show 10</option>
                        <option value={20}>Show 20</option>
                        <option value={30}>Show 30</option>
                      </select>
                    </Col>
                  </Row>
                </header>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Quotation Number</th>
                          <th>Customer name</th>
                          <th>Price</th>
                          {/* <th>Status</th> */}
                          <th>Convert  Order</th>
                          <th>Date</th>
                          <th >view</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userCotationData?.data?.map((order) => (
                          <tr key={order?.id}>
                            <td>{order?.cotation_no}</td>
                            {console.log(order,'fkjgld')
                            }
                            <td>
                              <b>{order?.user?.name}</b>
                            </td>

                            <td>
                              {/* {order?.total_price != null
                                ? parseFloat(Number(order?.total_price)+Number(order?.tax_price)).toFixed(2)
                                : parseFloat(Number(order?.sub_total)+Number(order?.tax_price)).toFixed(2)} */}
                              ₹
                              {order?.total_price != null
                                ? parseFloat(order?.total_price).toFixed(2)
                                : parseFloat(order?.sub_total).toFixed(2)}
                            </td>
                          
                               <td className=" ">
                              
                             

                              <button
                                className="btn btn-md rounded font-sm"
                                onClick={() =>
                                //   navigate(`/pageordersdetail/${order?.id}`)
                                changeOrder(order?.id)
                                // console.log(order?.id,'jfs777')
                                
                                }
                              >
                                Order
                              </button>

                              
                            </td>
                            <td>
                              {dayjs(order?.created_at).format("DD.MM.YYYY")}
                            </td>
                            <td className=" ">
                              
                             

                              <button
                                className="btn btn-md rounded font-sm"
                                onClick={() =>
                                  navigate(`/pagecotationdetails/${order?.id}`)
                                }
                              >
                                Detail
                              </button>

                              
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="pagination-area mt-15 mb-50">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                  <li
                          className={`page-item a ${
                            page === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link page-prev"
                            onClick={() => onPageChange(1)}
                          >
                            First
                          </a>
                        </li>
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="material-icons md-chevron_left"></i>
                      </a>
                    </li>
                    {console.log(totalPages1,'dd55d')
                    }
                    { userCotationData &&[...Array(totalPages1)]
                    ?.map((_, index) => index + 1) 
                    .filter((page) => {
                      
                      const startPage = Math.max(1, currentPage - 2); 
                      const endPage = Math.min(totalPages1, currentPage + 2); 
                      return page >= startPage && page <= endPage;
                    })
                    .map((page) => (
                      <li
                        key={page}
                        className={`page-item ${
                          currentPage === page ? "active" : ""
                        }`}
                        onClick={() => handlePageChange(page)}
                      >
                        <a className="page-link" href="#">
                          {page}
                        </a>
                      </li>
                    ))}


                    <li
                      className={`page-item ${
                        currentPage === totalPages1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="material-icons md-chevron_right"></i>
                      </a>
                    </li>
                    <li
                    className={`page-item ${
                      page === totalPages1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link page-next"
                      onClick={() => onPageChange(totalPages1)}
                    >
                      Last
                    </a>
                  </li>
                  </ul>
                </nav>
              </div>
            </Col>
            {/* <Col md={3}>
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="mb-3">Filter by</h5>
                  <form onSubmit={handleSubmit(filterSubmit)}
                    onKey={event => {
                      if (event.Key === 13 ) {
                        event.preventDefault();
                      }
                    }
                  }
                  >
                    <div className="mb-4">
                      <label className="form-label" htmlFor="order_id">
                        Order ID
                      </label>
                      <input
                        className="form-control"
                        id="order_id"
                        type="text"
                        placeholder="Type here"
                        {...register("order_id")}
                      />
                    </div>
                   
                    <div className="mb-4">
                      <label className="form-label" htmlFor="order_customer">
                        Customer Name
                      </label>
                      <Select
                        placeholder="Customer"
                        className="form-control"
                        options={[
                          { id: "", name: "All" },
                          ...(Array.isArray(userData?.data)
                            ? userData?.data
                            : []),
                          ,
                        ]}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        searchBy="name"
                        labelField={"name"}
                        valueField={"id"}
                        name="name"
                        onChange={(selectedOption) =>
                          setValue("customer", selectedOption[0].id)
                        }
                      
                      />
                    </div>
                
                    <div className="mb-4">
                      <label className="form-label">Order Status</label>
                      <select
                        className="form-select"
                        {...register("order_status")}
                      >
                        <option className="option" value={""}>
                          All
                        </option>
                        <option className="option" value={0}>
                          ORDER INITIATED
                        </option>
                        <option className="option" value={1}>
                          ONHOLD
                        </option>
                        <option className="option" value={2}>
                          DISPATCHED
                        </option>
                        <option className="option" value={3}>
                          DELIVERY IN PROCESS
                        </option>
                        <option className="option" value={4}>
                          DELIVERED
                        </option>
                        <option className="option" value={5}>
                          COMPLETED
                        </option>
                        <option className="option" value={6}>
                          PAID
                        </option>
                      </select>
                    </div>
                
                    <div className="mb-4">
                      <label className="form-label" htmlFor="order_total">
                        Date Range
                      </label>
                      <DateRangePicker
                        showOneCalendar
                        shouldDisableDate={afterToday()}
                        value={value}
                        onChange={(newValue) => setValues(newValue)}
                        placeholder="Select Date Range"
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-md rounded font-sm"
                        type="submit"
                      >
                        Filter
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Col> */}
          </Row>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Transport Chagres</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column ">
                <label className="mb-2"> Add Transport Chagres</label>

                <input
                  className="form-control mb-2"
                  type="text"
                  value={transport}
                  onChange={(e) => {
                    setTransport(e.target.value);
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAddCharges}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
        <Footer />
      </main>
    </>
  );
}

export default PageCotation;
