import React, { useEffect } from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import Select from "react-dropdown-select";
import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  statusData,
  orderStatusData,
  statusClasses,
} from "../../utils/Constants";
import { orderSelector } from "../../store/order/order.model";
import { productSelector } from "../../store/product/product.model";

import {
  getUserOrderDataRequest,
  orderDetailGetRequest,
  orderStateResetRequest,
  UpdateBillTypeStatusRequest,
  UpdateOrderStatusRequest,
  updateTpriceRequest,
} from "../../store/order/order.action";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { getUserDataRequest, userSelector } from "../../store/user/user.action";
import { useForm } from "react-hook-form";
import { createOrderPayload } from "../../utils/config";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { authSelector } from "../../store/auth/auth.action";

function Pageorders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* Selector */
  const {
    userOrderData,
    updateStatusSuccess,
    TpriceSuccess,
    billTypeSuccess,
    orderDetailData,
  } = useSelector(orderSelector);
  const { userData, screenData } = useSelector(userSelector);
  console.log(userData, "userDAa");
  console.log(
    orderDetailData[0],
    orderDetailData[0]?.transport_charge,
    orderDetailData[0]?.vechile_no,
    "orderDetailData"
  );
  console.log(
    orderDetailData[0]?.transport_charge != null &&
      orderDetailData[0]?.transport_charge,
    "dfjlsdj"
  );

  console.log(userOrderData, updateStatusSuccess, "ssdfe54");
  const { afterToday } = DateRangePicker;
  const { getProductData } = useSelector(productSelector);
  // const { screen } = useSelector(authSelector);

  // const {userData } = useSelector(userSelector);
  // const screen = Array.isArray(userData?.data) && userData?.data[0]?.screen || {};
  const screen = screenData?.response?.screen || {};
  /* useFormHook */
  const { register, handleSubmit, setValue, reset } = useForm();
  /* State */
  // const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [transport, setTransport] = useState();
  const [vechile, setVechile] = useState(
    orderDetailData[0]?.vechile_no != null && orderDetailData[0]?.vechile_no
  );
  const [e_way, setE_Way] = useState(
    orderDetailData[0]?.e_way != null && orderDetailData[0]?.e_way
  );
  const [value, setValues] = useState(null);
  const [show, setShow] = useState(false);
  const [sel_id, setSelId] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setSelId(id);
  };

  /* Function */
  const totalPages1 = Math.ceil(userOrderData.count / rowsPerPage) || 1;

  const startIndex = (page - 1) * rowsPerPage;
  let endIndex = [page] * rowsPerPage;
  if (endIndex > userOrderData?.count) {
    endIndex = userOrderData?.count;
  }
  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 =
    currentPage < totalPages1
      ? currentPage + 1 < totalPages1
        ? currentPage + 1
        : null
      : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1 && currentPage > 3
        ? currentPage + 2 > totalPages1
          ? null
          : currentPage + 2
        : null
      : null;
  const lastPage_before =
    currentPage > 3 && totalPages1 != currentPage
      ? totalPages1 > currentPage + 2
        ? "..."
        : null
      : null;
  const lastPage =
    currentPage > 3 && totalPages1 - 1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  console.log(pagesToDisplay, "jdfkjdsfhkh");

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const billTypeChane = (value, idxx) => {
    console.log(value, idxx, "qwertyuhgf");
    let data = {
      id: idxx,
      payload: { type: Number(value) },
    };
    dispatch(UpdateBillTypeStatusRequest(data));
  };

  const handleAddCharges = () => {
    console.log(transport, sel_id, "h");
    let payload = {
      payload: {
        transport_charge: transport,
        vechile_no: vechile,
        e_way: e_way,
      },

      id: sel_id,
    };
    dispatch(updateTpriceRequest(payload));
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const onStatusChanges = (eve, id) => {
    console.log(eve, id, "evvid");
    const payload = {
      id,
      status: eve,
    };
    dispatch(UpdateOrderStatusRequest(payload));
  };
  const onPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages1) {
      setPage(newPage);
    }
  };

  const filterSubmit = (formData) => {
    formData.from_date = value?.[0] ?? "";
    formData.to_date = value?.[1] ?? "";
    console.log(formData, "56dfd78");
    const payload = createOrderPayload(
      page,
      rowsPerPage,
      "",
      "",
      formData?.customer,
      formData?.order_status,
      formData?.order_id,
      formData?.from_date,
      formData?.to_date
    );
    dispatch(getUserOrderDataRequest(payload));
    reset();
    let cistomuerReste = [{ id: "", name: "All" }];
    setValue("customer", cistomuerReste[0]);
    setValues(null);
  };

  /* UseState */
  useEffect(() => {
    console.log(TpriceSuccess, "TpriceSuccess");

    if (TpriceSuccess === true) {
      setTransport("");
      setVechile("");
      setE_Way("");
      setShow(false);

      const payload = createOrderPayload(page, rowsPerPage);
      dispatch(getUserOrderDataRequest(payload));
      dispatch(orderStateResetRequest());
    }
  }, [TpriceSuccess]);
  useEffect(() => {
    if (updateStatusSuccess || billTypeSuccess) {
      const payload = createOrderPayload(page, rowsPerPage);
      dispatch(getUserOrderDataRequest(payload));
      dispatch(orderStateResetRequest());
    }
  }, [updateStatusSuccess, billTypeSuccess]);

  useEffect(() => {
    const payload = createOrderPayload(
      page,
      rowsPerPage,
      searchQuery,
      statusFilter
    );
    dispatch(getUserOrderDataRequest(payload));
    const payload1 = {
      query: searchQuery,
      page: 0,
      limit: 0,
      userId: "",
      role_id: [4],
    };
    dispatch(getUserDataRequest(payload1));
  }, [searchQuery, statusFilter, rowsPerPage, currentPage]);
  useEffect(() => {
    if (Array.isArray(orderDetailData)) {
      setTransport(
        orderDetailData[0]?.transport_charge != null
          ? orderDetailData[0]?.transport_charge
          : ""
      );
      setVechile(
        orderDetailData[0]?.vechile_no != null
          ? orderDetailData[0]?.vechile_no
          : ""
      );
      setE_Way(
        orderDetailData[0]?.e_way != null ? orderDetailData[0]?.e_way : ""
      );
    } else {
      setTransport("");
      setVechile("");
      setE_Way("");
    }
  }, [orderDetailData]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Orders List</h2>
            </div>
            <div>
              {screen?.["order-add"] && (
                <button
                  className="btn btn-primary"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/pageorders/add");
                    // dispatch(pcStateResetRequest());
                  }}
                >
                  <i className="material-icons md-plus"></i> Create new
                </button>
              )}
            </div>
          </div>
          <Row>
            <Col md={10}>
              <div className="card mb-4">
                <header className="card-header">
                  <Row className="gx-3">
                    <Col lg={4} md={6} className="me-auto">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </Col>
                    <Col lg={2} md={3} className="col-6">
                      <select
                        className="form-select"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                      >
                        <option>Status</option>
                        <option>Active</option>
                        <option>Disabled</option>
                        <option>Show all</option>
                      </select>
                    </Col>
                    <Col lg={2} md={3} xs={6}>
                      <span className="font-md color-gray-900 font-medium border-1-right span">
                        <h6>
                          {" "}
                      {/* {userOrderData?.count>endIndex ? `Showing ${startIndex}- ${endIndex} of  ${userOrderData?.count} result`:`Showing ${startIndex}- ${userOrderData?.count} of ${userOrderData?.count} result`} */}

                          Showing {startIndex}&ndash;{endIndex} <br /> of{" "}
                          {userOrderData?.count} results
                        </h6>
                      </span>
                    </Col>
                    <Col lg={2} md={3} sm={6}>
                      <select
                        className="form-select"
                        onChange={(e) => setRowsPerPage(e.target.value)}
                      >
                        <option value={10}>Show 10</option>
                        <option value={20}>Show 20</option>
                        <option value={30}>Show 30</option>
                      </select>
                    </Col>
                  </Row>
                </header>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Order Number</th>
                          <th>Customer name</th>
                          <th>Price</th>
                          <th>Status</th>
                          <th>Order Status</th>
                          <th>Manage Status</th>
                          <th>Date</th>
                          <th colSpan={3}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userOrderData?.data?.map((order) => (
                          <tr key={order?.orders?.id}>
                            <td>{order?.order_no}</td>

                            <td>
                              <b>{order?.user?.name}</b>
                            </td>

                            <td>
                              {/* {order?.total_price != null
                                ? parseFloat(Number(order?.total_price)+Number(order?.tax_price)).toFixed(2)
                                : parseFloat(Number(order?.sub_total)+Number(order?.tax_price)).toFixed(2)} */}
                              ₹
                              {order?.total_price != null
                                ? parseFloat(order?.total_price).toFixed(2)
                                : parseFloat(order?.sub_total).toFixed(2)}
                            </td>
                            <td>
                              <span
                                className={`badge rounded-pill ${
                                  statusClasses[order?.status] ||
                                  "alert-secondary"
                                }`}
                              >
                                {statusData[order?.status]}
                              </span>
                            </td>
                            <td>
                              <select
                                className="form-select d-inline-block  "
                                onChange={(e) => {
                                  console.log(e.target.value, "hgkfhgid");
                                  // setBillType(e.target.value);
                                  billTypeChane(e.target.value, order?.id);
                                }}
                                value={
                                  order?.bill_type != null && order?.bill_type
                                }
                              >
                                <option className="option me-2  " value={""}>
                                  SELECT
                                </option>
                                <option className="option" value={1}>
                                  ESTIMATE
                                </option>
                                <option className="option" value={2}>
                                  INVOICE
                                </option>
                              </select>
                            </td>
                            <td>
                              <select
                                name=""
                                className="form-select w-75"
                                onClick={(e) =>
                                  onStatusChanges(e.target.value, order?.id)
                                }
                              >
                                <option className="option" value={""}>
                                  SELECT
                                </option>
                                <option className="option" value={0}>
                                  ORDER INITIATED
                                </option>
                                <option className="option" value={1}>
                                  ONHOLD
                                </option>
                                <option className="option" value={2}>
                                  DISPATCHED
                                </option>
                                <option className="option" value={3}>
                                  DELIVERY IN PROCESS
                                </option>
                                <option className="option" value={4}>
                                  DELIVERED
                                </option>
                                <option className="option" value={5}>
                                  COMPLETED
                                </option>
                                <option className="option" value={6}>
                                  PAID
                                </option>
                              </select>
                            </td>
                            <td colSpan={3}>
                              {dayjs(order?.created_at).format("DD.MM.YYYY")}
                            </td>
                            <td className=" ">
                              <div className="dropdown ">
                                <a
                                  className="btn btn-light rounded btn-sm font-sm"
                                  href="#"
                                  data-bs-toggle="dropdown"
                                >
                                  <i className="material-icons md-more_horiz"></i>
                                </a>
                                <div className="dropdown-menu ">
                                  <Button
                                    variant=" dropdown-item"
                                    onClick={() => {
                                      //  dispatch(orderStateResetRequest())
                                      const payload = {
                                        id: order?.id,
                                      };
                                      dispatch(orderDetailGetRequest(payload));
                                      handleShow(order?.id);
                                    }}
                                  >
                                    {/* ₹ */}
                                    Transport Charges
                                  </Button>
                                  <button
                                    className="dropdown-item "
                                    onClick={() => {
                                      dispatch(orderStateResetRequest());
                                      navigate(`/pageorders/add/${order?.id}`);
                                    }}
                                  >
                                    Edit
                                  </button>

                                  <button
                                    className=" dropdown-item"
                                    onClick={() => {
                                      dispatch(orderStateResetRequest());
                                      navigate(
                                        `/pageordersdetail/${order?.id}`
                                      );
                                    }}
                                  >
                                    Detail
                                  </button>
                                </div>
                              </div>

                              {/* <button
                                className="btn btn-md rounded font-sm"
                                onClick={() =>
                                  navigate(`/pageorders/add/${order?.id}`)
                                }
                              >
                                Add 
                              </button> */}

                              {/* <div className="dropdown">
                                <a
                                  className="btn btn-light rounded btn-sm font-sm"
                                  href="#"
                                  data-bs-toggle="dropdown"
                                >
                                  <i className="material-icons md-more_horiz"></i>
                                </a>
                                <div className="dropdown-menu">
                                  <a className="dropdown-item" href="#">
                                    View detail
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    Edit info
                                  </a>
                                  <a
                                    className="dropdown-item text-danger"
                                    href="#"
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="pagination-area mt-15 mb-50">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <li
                      className={`page-item a ${page === 1 ? "disabled" : ""}`}
                    >
                      <a
                        className="page-link page-prev"
                        onClick={() => onPageChange(1)}
                      >
                        First
                      </a>
                    </li>
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        <i className="material-icons md-chevron_left"></i>
                      </a>
                    </li>

                    {/* {pagesToDisplay.map((page) => {
                      return page != "..." ? (
                        <li
                          key={page}
                          className={`page-item b ${
                            currentPage === page ? "active" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </a>
                        </li>
                      ) : page == "..." ? (
                        <li
                          key={page}
                          className={`page-item ${
                            currentPage === page ? "active" : ""
                          }`}
                        >
                          <a className="page-link" href="#">
                            {page}
                          </a>
                        </li>
                      ) : (
                        ""
                      );
                    })} */}
                    {[...Array(totalPages1)]
                      ?.map((_, index) => index + 1)
                      .filter((page) => {
                        const startPage = Math.max(1, currentPage - 2);
                        const endPage = Math.min(totalPages1, currentPage + 2);
                        return page >= startPage && page <= endPage;
                      })
                      .map((page) => (
                        <li
                          key={page}
                          className={`page-item ${
                            currentPage === page ? "active" : ""
                          }`}
                          onClick={() => handlePageChange(page)}
                        >
                          <a className="page-link" href="#">
                            {page}
                          </a>
                        </li>
                      ))}

                    <li
                      className={`page-item ${
                        currentPage === totalPages1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        <i className="material-icons md-chevron_right"></i>
                      </a>
                    </li>
                    <li
                      className={`page-item ${
                        page === totalPages1 ? "disabled" : ""
                      }`}
                    >
                      <a
                        className="page-link page-next"
                        onClick={() => onPageChange(totalPages1)}
                      >
                        Last
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </Col>
            <Col md={2}>
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="mb-3">Filter by</h5>
                  <form
                    onSubmit={handleSubmit(filterSubmit)}
                    onKey={(event) => {
                      if (event.Key === 13 /* Enter */) {
                        event.preventDefault();
                      }
                    }}
                  >
                    <div className="mb-4">
                      <label className="form-label" htmlFor="order_id">
                        Order ID
                      </label>
                      <input
                        className="form-control"
                        id="order_id"
                        type="text"
                        placeholder="Type here"
                        {...register("order_id")}
                      />
                    </div>
                    {/* Customer */}
                    <div className="mb-4">
                      <label className="form-label" htmlFor="order_customer">
                        Customer Name
                      </label>
                      <Select
                        placeholder="Customer"
                        className="form-control"
                        options={[
                          { id: "", name: "All" },
                          ...(Array.isArray(userData?.data)
                            ? userData?.data
                            : []),
                          ,
                        ]}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        searchBy="name"
                        labelField={"name"}
                        valueField={"id"}
                        name="name"
                        onChange={(selectedOption) =>
                          setValue("customer", selectedOption[0].id)
                        }
                        // value={selectedCustomer}
                        // onChange={handleCustomerChange}
                      />
                    </div>
                    {/* Order Status */}
                    <div className="mb-4">
                      <label className="form-label">Order Status</label>
                      <select
                        className="form-select"
                        {...register("order_status")}
                      >
                        <option className="option" value={""}>
                          All
                        </option>
                        <option className="option" value={0}>
                          ORDER INITIATED
                        </option>
                        <option className="option" value={1}>
                          ONHOLD
                        </option>
                        <option className="option" value={2}>
                          DISPATCHED
                        </option>
                        <option className="option" value={3}>
                          DELIVERY IN PROCESS
                        </option>
                        <option className="option" value={4}>
                          DELIVERED
                        </option>
                        <option className="option" value={5}>
                          COMPLETED
                        </option>
                        <option className="option" value={6}>
                          PAID
                        </option>
                      </select>
                    </div>
                    {/* Date Added */}
                    <div className="mb-4">
                      <label className="form-label" htmlFor="order_total">
                        Date Range
                      </label>
                      <DateRangePicker
                        showOneCalendar
                        shouldDisableDate={afterToday()}
                        value={value}
                        onChange={(newValue) => setValues(newValue)}
                        placeholder="Select Date Range"
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-md rounded font-sm"
                        type="submit"
                      >
                        Filter
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Transport Chagres</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column ">
                <label className="mb-2"> Add Transport Chagres</label>

                <input
                  className="form-control mb-2"
                  type="text"
                  value={transport}
                  onChange={(e) => {
                    setTransport(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex flex-column ">
                <label className="mb-2"> Add Vechile No</label>

                <input
                  className="form-control mb-2"
                  type="text"
                  value={vechile}
                  onChange={(e) => {
                    setVechile(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex flex-column ">
                <label className="mb-2"> Add E-Way Bill No</label>

                <input
                  className="form-control mb-2"
                  type="text"
                  value={e_way}
                  onChange={(e) => {
                    setE_Way(e.target.value);
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAddCharges}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
        <Footer />
      </main>
    </>
  );
}

export default Pageorders;
