import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Footer from "../Footer";
import _ from "loadsh";
import {
  ProductAddListValiator,
  VendorAddValidator,
} from "../../validators/Validators";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserDataRequest,
  getUserDataRequest,
  logout,
  updateUserRequest,
  userSelector,
} from "../../store/user/user.action";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-dropdown-select";
import { vendorTypes } from "../../utils/Constants";
import { NodeServer } from "../../services/config";
const VendorsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams();
  console.log(id,"poiu")
  const [selectedOption2, setSelectedOption2] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(VendorAddValidator),
  });
  /* Selectors */
  const { createSuccess, userData, updateSuccess } = useSelector(userSelector);
  console.log(userData, "userDAsa");

  /* State */
  const [ids, setId] = useState(0);
  const [photo, setPhoto] = useState();
  console.log(ids, "sds");

  /* Fucntions */

  const onSubmitHandler = (formData) => {
    formData.role_id =4;
    formData.photo=photo
    console.log(formData,"qsc");
    
    if (formData.credit_limit == "") {
      formData.credit_limit = 0;
    }
    if (formData.duedays_limit == "") {
      formData.duedays_limit = 0;
    }
    // console.log(formData, "sldjfsdsd");
    if (ids == 0) {
      console.log(formData,'sldjfsdsd');
      
      dispatch(createUserDataRequest(formData));
    } else {
      const payload = {
        id: ids,
        formData,
      };
      formData.id=ids
      dispatch(updateUserRequest(formData));
    }
  };
  const changeType=(sel)=>{
    console.log(sel[0],'qwert');
                              
    setValue("vendor_type", sel[0]?.value);
    setSelectedOption2(sel);

  }

  /* useEffect */
  useEffect(() => {
    if (id && id.id) {
      setId(id.id);
    }
  }, [id]);

  useEffect(() => {
    if (ids > 0) {
      const payload = {
        query: "",
        page: 0,
        limit: 0,
        userId: ids,
        role_id:[4],
      };
      dispatch(getUserDataRequest(payload));
    }
  }, [ids]);
  useEffect(() => {
    console.log(userData,'lkjhg');
    
    if (userData?.count > 0) {
      userData?.data?.map((ele) => {
        let vendor_type = _.filter(vendorTypes,{"id":ele?.vendor_type})
        console.log(ele,"plm");
        
        console.log(vendor_type[0],"Vtype")
       
        let opt=vendor_type[0]
        console.log(opt,'vtype');
        changeType(vendor_type)
        setPhoto(
          ele?.image?.includes("/products/users/")
            ? NodeServer.baseURL + ele?.photo
            : NodeServer.baseURL + "/products/users/" + ele?.photo
        );
        console.log(photo,"pkm");
        
        reset({
          email: ele?.email,
          mobile: ele?.mobile,
          name: ele?.name,
          password: ele?.password,
          role_id: ele?.role_id,
          credit_limit: ele?.credit_limit,
          duedays_limit: ele?.duedays_limit,
          address:ele?.address,
          zipcode:ele?.zipcode,
          city:ele?.city,
          vendor_type:ele?.vendor_type,
          contact_person:ele?.contact_person,
          area:ele?.area

        });
      });
    }
  }, [userData]);
  useEffect(() => {
    if (createSuccess || updateSuccess) {
      navigate("/pagesellerslist");
      dispatch(logout());
      reset();
    }
  }, [createSuccess, updateSuccess]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <form onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} onSubmit={handleSubmit(onSubmitHandler)}>
            <Row>
              <Col md={12}>
                <div className="content-header">
                  <h2 className="content-title">
                    {ids === 0 || ids == undefined
                      ? "Add New Vendor"
                      : "Edit Vendor"}
                  </h2>
                  <div>
                    <button
                      className="btn btn-md rounded font-sm hover-up"
                      type="submit"
                    > {ids === 0 || ids == undefined
                      ? "Save Vendor"
                      : "Update Vendor"}
                      
                    </button>
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="card mb-4">
                  <div className="card-header">
                    <h4>Basic Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="name">
                          Name
                        </label>
                        <input
                          className="form-control"
                          id="name"
                          type="text"
                          {...register("name")}
                          placeholder="Enter your name"
                        />
                        {errors?.name && (
                          <p className="text-danger">{errors?.name?.message}</p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="contact_person">
                          Contact Person
                        </label>
                        <input
                          className="form-control"
                          id="contact_person"
                          type="text"
                          {...register("contact_person")}
                          placeholder="Enter your Contact Person"
                        />
                        {errors?.contact_person && (
                          <p className="text-danger">
                            {errors?.contact_person?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="photo">
                          Photo
                        </label>
                        <input
                          className="form-control"
                          id="photo"
                          type="file"
                          onChange={(e)=>{
                            console.log(e.target.value,"etv");
                            setPhoto(e.target.files[0])
                            
                          }}
                          // {...register("name")}
                          // placeholder="Enter your name"
                        />
                       
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="email">
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="email"
                          type="email"
                          {...register("email")}
                          placeholder="Enter your email"
                        />
                        {errors?.email && (
                          <p className="text-danger">
                            {errors?.email?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="mobile">
                          Mobile
                        </label>
                        <input
                          className="form-control"
                          id="mobile"
                          type="tel"
                          {...register("mobile")}
                          placeholder="Enter your mobile number"
                        />
                        {errors?.mobile && (
                          <p className="text-danger">
                            {errors?.mobile?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="password"
                          type="password"
                          {...register("password")}
                          placeholder="Enter your password"
                        />
                        {errors?.password && (
                          <p className="text-danger">
                            {errors?.password?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="role_id">
                          GST Number
                        </label>
                        <input
                          className="form-control"
                          id="role_id"
                          type="text"
                          {...register("gst_no")}
                          placeholder="Enter GST Number"
                        />
                        {errors?.role_id && (
                          <p className="text-danger">
                            {errors?.role_id?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="city">
                          City
                        </label>
                        <input
                          className="form-control"
                          id="city"
                          type="text"
                          {...register("city")}
                          placeholder="Enter your city"
                        />
                        {errors?.city && (
                          <p className="text-danger">{errors?.city?.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="zipcode">
                          Zipcode
                        </label>
                        <input
                          className="form-control"
                          id="zipcode"
                          type="text"
                          {...register("zipcode")}
                          placeholder="Enter your zipcode"
                        />
                        {errors?.zipcode && (
                          <p className="text-danger">
                            {errors?.zipcode?.message}
                          </p>
                        )}
                      </div>

                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="address">
                          Address
                        </label>
                        <textarea
                          className="form-control"
                          id="address"
                          {...register("address")}
                          placeholder="Enter your address"
                          rows="3"
                        ></textarea>
                        {errors?.address && (
                          <p className="text-danger">
                            {errors?.address?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="area">
                          Area
                        </label>
                        <input
                          className="form-control"
                          id="area"
                          type="text"
                          {...register("area")}
                          placeholder="Enter Your Area"
                        />
                       
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="role_id">
                          Credit Limit
                        </label>
                        <input
                          className="form-control"
                          id="role_id"
                          type="text"
                          {...register("credit_limit")}
                          placeholder="Enter Credit Limit"
                        />
                        {errors?.credit_limit && (
                          <p className="text-danger">
                            {errors?.credit_limit?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="city">
                          Due Days Limit
                        </label>
                        <input
                          className="form-control"
                          id="city"
                          type="number"
                          {...register("duedays_limit")}
                          placeholder="Enter Due Days"
                        />
                        {errors?.duedays_limit && (
                          <p className="text-danger">
                            {errors?.duedays_limit?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="city">
                          Vendor Type
                        </label>
                        <Select
                            placeholder="Vendor Type"
                            className="form-control"
                            options={vendorTypes || []}
                            disabled={false}
                            dropdownHandle={true}
                            searchable={true}
                            searchBy="name"
                            dropdownPosition="top"
                            labelField={"name"}
                            valueField={"value"}
                            values={selectedOption2 || []}
                            name="name"
                            onChange={(selectedOption) => {
                              // console.log(selectedOption2[0],'vtype');
                              
                              // setValue("vendor_type", selectedOption[0]?.value);
                              // setSelectedOption2(selectedOption);
                              changeType(selectedOption)
                            }}
                          />
                        {errors?.vendor_type && (
                          <p className="text-danger">
                            {errors?.vendor_type?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default VendorsPage;
