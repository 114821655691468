import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "react-bootstrap";
import classnames from "classnames";
import "../../App.css";
import { useEffect, useState, useMemo } from "react";
import Select from "react-dropdown-select";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import dayjs from "dayjs";
import TableRow from "@mui/material/TableRow";
import TablePaginationActions from "../../utils/pagination";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import { addInvoice } from "../../utils/Validation";
import { useNavigate, useParams } from "react-router";
import toastr from "toastr";
import _ from "loadsh";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { productSelector } from "../../store/product/product.model";
import { TaxPercentage } from "../../utils/Constants";
import {
  getPurchaseOrderByIdDataRequest,
  poStateReset,
  postPurchaseOrderDataRequest,
  updatePurchaseOrderDataRequest,
} from "../../store/PurchaseOrder/po.action";
import { getProductRequest } from "../../store/product/product.action";
import { companySelector } from "../../store/PurchaseCompany/pc.model";
import { getPurchaseCompanyDataRequest } from "../../store/PurchaseCompany/pc.action";
import { purchaseOrderSelector } from "../../store/PurchaseOrder/po.model";
import DeleteIcon from "@mui/icons-material/Delete";

const AddPurchaseInvoice = () => {
  const id = useParams();
  console.log(id.id, "qqq");

  const { getProductData } = useSelector(productSelector);
  const { companyData } = useSelector(companySelector);
  const { UpdateSuccess, PostSuccess, PodataById } = useSelector(
    purchaseOrderSelector
  );
  console.log(PodataById, "ssss");
  console.log(getProductData, "getss");

  const [activeTab, setActiveTab] = useState("5");
  const dispatch = useDispatch();

  const [voucherDetails, setVoucherDetails] = useState([
    {
      additional_description: "",
      product_id: 0,
      quantity: 0,
      unit: "0",
      unit_price: 0,
      old_price: 0,
      amount: 0,
      tax: "",
      discount_val: 0,
      id: 0,
      price_category_id: "",
    },
  ]);
  console.log(voucherDetails, "voucherDetails");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(addInvoice),
  });
  console.log(errors, "ERRORS");

  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState("customer_name");
  const [activesortBy, setActiveSortBy] = useState("customer_name");
  const [page, setPage] = useState(0);

  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [current_bal, setCurrentBal] = useState(0);
  const [invoiceDD, setinvoiceDD] = useState("");
  const [totalAmt, settotalAmt] = useState(0);
  const [discount_val, setDiscountVal] = useState("0");
  const [roundOffAmt, setroundOffAmt] = useState("0");
  const [vat_amount, setVatAmount] = useState("0");
  const [freightCharge, setfreightCharge] = useState("0");
  const [label, setLabel] = useState("label");
  const [taxType, setTaxType] = useState(TaxPercentage);
  let [purchaseDateVal, setpurchaseDateVal] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  useEffect(() => {
    console.log("kfgfsh");

    if (PodataById) {
     
      setValue("purchase_company_id", PodataById?.purchase_company_id);

      let vdData = PodataById?.purchase_invoice_details?.map(
        (orderDatas, key) => {
          return {
            additional_description: orderDatas?.additional_description,
            product_id: orderDatas?.product_id,
            quantity: orderDatas?.qty,
            tax: orderDatas?.tax,
            unit: "1",
            unit_price: orderDatas?.price,
            amount: Number(orderDatas?.amount),
            discount_val: orderDatas?.discount_val,
            id: key++,
          };
        }
      );
      console.log(vdData, "vData");
      if (vdData) {
        console.log(PodataById.discount_amount, "dhfkhadkh");

        setVoucherDetails([...vdData, ...voucherDetails]);
        // setDiscountVal(PodataById?.discount_amount);
        // setroundOffAmt(PodataById?.round_off);
        // setValue("net_amount", PodataById?.net_amount);
        // setValue("invoice_no", PodataById?.invoice_no);
        // clearErrors("net_amount");
        settotalAmt(PodataById.total_amount || 0);
        setinvoiceDD(PodataById.invoice_due_date || "");
        setDiscountVal(PodataById.discount_amount || 0);
        setroundOffAmt(PodataById.round_amount || "0");
        setVatAmount(PodataById.vat_amount || "0");
        setfreightCharge(PodataById.freight_amount || "0");
        setCurrentBal(PodataById.invoice_current_balance || 0);
        setpurchaseDateVal(
          PodataById?.purchase_date || dayjs().format("YYYY-MM-DD")
        );
        setValue("due_days", PodataById?.due_days);
        setValue("invoice_no", PodataById?.invoice_no);
        setValue("purchase_company_id", PodataById?.purchase_company_id);
        setValue("notes", PodataById.notes);
        setValue("net_amount", PodataById?.net_amount);
        // let total=0
        // voucherDetails?.map((product)=>{
        //   return total+product.amount
        // })
        // setTotal(total)
        // setValue("net_amount", total);
        console.log(discount_val, "dhfkhadkh");

        const parsedTaxType = PodataById?.tax_type
          ? JSON.parse(PodataById.tax_type)
          : null;
        if (parsedTaxType) {
          const matchingTax = TaxPercentage.find(
            (tax) => tax.name === parsedTaxType.name
          );
          setTaxType([matchingTax || TaxPercentage[1]]);
        } else {
          setTaxType([TaxPercentage[1]]);
        }
      }
    }
  }, [PodataById]);
  function allKeysExceptNarrationHaveValues(obj) {
    const keysToCheck = _.omit(obj, "narration", "id");
    console.log(
      keysToCheck,
      "allObjectsKeysExceptNarrationHaveValues(arrayOfObjects)"
    );
    return _.every(keysToCheck, (value) => !_.isEmpty(value));
  }

  function allObjectsKeysExceptNarrationHaveValues(array) {
    return _.every(array, (obj) => allKeysExceptNarrationHaveValues(obj));
  }

  const onSubmitHandler = (formData) => {
    // console.log(
    //   allObjectsKeysExceptNarrationHaveValues(voucherDetails),
    //   voucherDetails,
    //   "allObjectsKeysExceptNarrationHaveValues(arrayOfObjects)"
    // );
    // console.log(voucherDetails, "voucherDetails1");
    let details = voucherDetails?.map((ele) => {
      console.log(ele.price_category_id, "plm");

      return {
        product_id: ele.product_id,
        additional_description: ele.additional_description,
        quantity: ele.quantity,
        unit: ele.unit,
        tax: ele.tax,
        discount_val: ele.discount_val,
        old_price: ele.old_price,
        unit_price: ele.unit_price,
        amount: ele.amount,
        price_category_id: ele.price_category_id,
      };
    });
    formData.details = details;
    formData.purchase_date = purchaseDateVal;
    formData.invoice_due_date = invoiceDD;
    formData.total_amount = totalAmt;
    formData.discount_amount = discount_val;
    formData.vat_amount = vat_amount;
    formData.round_amount = roundOffAmt;
    formData.freight_amount = freightCharge;
    formData.net_amount = getValues("net_amount");
    formData.billAmount = getValues("net_amount");
    // formData.tax_type = taxType[0];
    // let currentBalance =
    //   parseFloat(current_bal) - parseFloat(getValues("net_amount"));
    // if (currentBalance < 0) {
    //   toastr.error("Invalid Balance");
    // } else {
    console.log(formData, "223sdf3");
    // return
    if (id?.id && id.id !== "0") {
      formData.id = id.id;
      if (formData.total_amount > 0) {
        dispatch(updatePurchaseOrderDataRequest({ formData, id }));
        // toastr.success("Select Products");
      } else {
        toastr.error("Select Products");
      }
    } else {
      if (formData.total_amount > 0) {
        dispatch(postPurchaseOrderDataRequest(formData));
        // toastr.success("Select Products");
      } else {
        toastr.error("Select Products");
      }
    }
    // }
  };

  const handleinvoiceDueDate = (event, type) => {
    let invoiceDueDate = "";
    let quotation_date = "";

    if (type == "invoice_date") {
      invoiceDueDate = event?.target?.value;
      quotation_date = purchaseDateVal;
      setinvoiceDD(invoiceDueDate);
    } else if (type == "quotation_date") {
      quotation_date = event?.target?.value;
      setpurchaseDateVal(quotation_date);
      invoiceDueDate = invoiceDD;
    }

    let date1 = new Date(invoiceDueDate);
    let date2 = new Date(quotation_date);

    let Difference_In_Time = date1.getTime() - date2.getTime();
    let Difference_In_Days = Math.round(
      Difference_In_Time / (1000 * 3600 * 24)
    );
    if (Difference_In_Days < 0) {
      toastr.clear();
      toastr.error("Please choose the valid Date!");
      setinvoiceDD("");
      setValue("due_days", 0);
    } else setValue("due_days", invoiceDueDate != "" ? Difference_In_Days : 0);
  };
  const calculateAmount = (index) => {
    const data = [...voucherDetails];
    console.log(index, data, "index");
    console.log(data[index]?.discount_val, "75694fdf");

    if (index < 0 || index >= data.length) {
      console.error("Invalid index provided");
      return;
    }

    const qty = parseFloat(data[index].quantity) || 0;
    const unitPrice = parseFloat(data[index].unit_price) || 0;
    const baseAmount = qty * unitPrice;

    const discountPercentage = parseFloat(data[index]?.discount_val || 0);
    if (discountPercentage > 100) {
      toastr.error("Please enter a discount percentage between 0 and 100.");
      return;
    }
    const discountedAmount = Math.max(baseAmount - discountPercentage, 0);
    console.log(discountedAmount, "discountedAmount");

    // const taxPercentage = data[index]?.tax === "VAT 15%" ? 15 : 0 ?? 0;
    const taxPercentage = (() => {
      switch (data[index].tax) {
        case "G5":
          return 5;
        case "G12":
          return 12;
        case "G18":
          return 18;
        case "G28":
          return 28;
        default:
          return 0;
      }
    })();
    console.log(taxPercentage, "index");
    const taxAmount = (taxPercentage / 100) * baseAmount;
    let finalAmount = 0;
    if (data[index]?.discount_val > 0) {
      let amt = baseAmount + taxAmount;
      finalAmount = amt - amt * (data[index]?.discount_val / 100);
    } else {
      finalAmount = baseAmount + taxAmount;
    }

    console.log(taxAmount, "fgdfgdhdjf");
    data[index] = {
      ...data[index],
      amount: finalAmount.toFixed(2),
    };
    console.log(data, "index");
    setVoucherDetails(data);
    updateTotals(data);
  };

  const updateTotals = (data) => {
    let totalAmt = data
      .reduce((sum, item) => {
        const qty = parseFloat(item.quantity) || 0;
        const unitPrice = parseFloat(item.unit_price) || 0;

        return sum + qty * unitPrice;
      }, 0)
      .toFixed(2);

    let vatAmount = data
      .reduce((sum, item) => {
        // const discountPercentage = parseFloat(item?.discount_val || 0);
        const baseAmount =
          (parseFloat(item.quantity) || 0) * (parseFloat(item.unit_price) || 0);
        // const discountedAmount = baseAmount - discountPercentage;
        const taxPercentage = (() => {
          switch (item.tax) {
            case "G5":
              return 5;
            case "G12":
              return 12;
            case "G18":
              return 18;
            case "G28":
              return 28;
            default:
              return 0;
          }
        })();
        return sum + (taxPercentage / 100) * baseAmount;
      }, 0)
      .toFixed(2);

    let netAmt = data
      .reduce((sum, item) => {
        const qty = parseFloat(item.quantity) || 0;
        const unitPrice = parseFloat(item.unit_price) || 0;
        const baseAmount = qty * unitPrice;
        // const discountPercentage = parseFloat(item?.discount_val || 0);
        // const discountedAmount = baseAmount - discountPercentage;
        const taxPercentage = item?.tax === "VAT 15%" ? 15 : 0;
        return sum + (baseAmount + (taxPercentage / 100) * baseAmount);
      }, 0)
      .toFixed(2);
    let discountTotal = data
      .reduce((sum, item) => {
        const discountPercentage = parseFloat(item?.discount_val || 0);
        const qty = parseFloat(item.quantity) || 0;
        const unitPrice = parseFloat(item.unit_price) || 0;
        const baseAmount = qty * unitPrice;
        const taxPercentage = (() => {
          switch (item.tax) {
            case "G5":
              return 5;
            case "G12":
              return 12;
            case "G18":
              return 18;
            case "G28":
              return 28;
            default:
              return 0;
          }
        })();
        const taxAmount = (taxPercentage / 100) * baseAmount;
        if (discountPercentage > 0) {
          let amt = baseAmount + taxAmount;
          let finalAmount = amt * (discountPercentage / 100);
          return sum + finalAmount;
        } else {
          return sum;
        }
      }, 0)
      .toFixed(2);
    console.log(discountTotal, "discountTotal");

    console.log(totalAmt, "iii");
    console.log(vatAmount, "iii");
    console.log(parseFloat(vatAmount) + parseFloat(totalAmt), "iii");
    let Total = (parseFloat(vatAmount) + parseFloat(totalAmt)).toFixed(2);
    if (discountTotal > 0) {
      Total = (Total - discountTotal).toFixed(2);
    }
    let TotalAmt = Math.floor(Total).toFixed(2);
    let roundOff = (Total - TotalAmt).toFixed(2);
    setroundOffAmt(roundOff);
    settotalAmt(totalAmt);
    setVatAmount(vatAmount);
    setDiscountVal(discountTotal);
    setValue("net_amount", TotalAmt);
  };

  useEffect(() => {
    const payload = {
      query: "",
      prod_id: 0,
      page: "",
      limit: "",
    };

    dispatch(getProductRequest(payload));
    dispatch(
      getPurchaseCompanyDataRequest({
        query: "",
        page: "",
        limit: "",
        id: "",
        status: [1, 0],
      })
    );
    // dispatch(poStateReset());
  }, [searchQuery, currentPage, rowsPerPage]);
  useEffect(() => {
    if (PostSuccess || UpdateSuccess) {
      navigate("/purchanseInovice");
      dispatch(poStateReset());
    }
  }, [PostSuccess, UpdateSuccess]);

  useEffect(() => {
    if (id?.id && id.id !== "0") {
      setVoucherDetails([
        {
          additional_description: "",
          product_id: 0,
          quantity: 0,
          unit: "0",
          unit_price: 0,
          old_price: 0,
          amount: 0,
          tax: "",
          discount_val: 0,
          id: 0,
          price_category_id: "",
        }
      ])
      dispatch(getPurchaseOrderByIdDataRequest(id));
    }
  }, [id]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="page-content">
            <div className="d-flex justify-content-between">
              <h2 className="content-title mb-3">
                {id.id === 0 || id.id === undefined
                  ? "Add Purchase Invoice"
                  : "Edit Purchase Invoice"}
              </h2>
              {/* <h4 className="page-title"></h4> */}
              <h5>Bill Amount : {getValues("net_amount")}</h5>
            </div>
            <form
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <div class="input-container w-100">
                          <label for="invoice_no">Invoice No</label>
                          <input
                            placeholder=" "
                            type="text"
                            id="invoice_no"
                            name="invoice_no"
                            className=" form-control"
                            {...register("invoice_no")}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="input-container">
                        <label htmlFor="vendor_id">
                          Select Purchase Customer
                        </label>
                        <Controller
                          name="vendor_id"
                          control={control}
                          render={({ field, ref }) => (
                            <Select
                              className="js-states form-control"
                              options={companyData?.data || []}
                              placeholder="Select Purchase Customer"
                              {...field}
                              ref={ref}
                              dropdownHandle={true}
                              searchable={true}
                              searchBy="company_name"
                              labelField={"company_name"}
                              valueField={"id"}
                              name="purchase_company_id"
                              values={
                                getValues("purchase_company_id")
                                  ? companyData?.data?.filter(
                                      (ele) =>
                                        getValues("purchase_company_id") ==
                                        ele.id
                                    )
                                  : []
                              }
                              onChange={(value) =>
                                setValue("purchase_company_id", value[0]?.id)
                              }
                            />
                          )}
                          {...register("purchase_company_id")}
                        />
                      </div>
                      {errors?.purchase_company_id?.message && (
                        <p className="error">
                          {errors.purchase_company_id.message}
                        </p>
                      )}
                    </Col>
                    {/* <Col md="4">
                      <div className="d-flex align-items-end">
                        <div class="input-container w-100">
                          <label for="quotation_date">Upload Invoice Pdf</label>
                          <input
                            placeholder=" "
                            type="file"
                            className="form-control w-100"
                            id="invoicepdf"
                            // onChange={(e) =>
                            //   handleinvoiceDueDate(e, "quotation_date")
                            // }
                            name="invoicepdf"
                            // value={purchaseDateVal}
                          />
                        </div>
                      </div>
                    </Col> */}

                    <Col md="4">
                      <div className="d-flex align-items-end">
                        <div class="input-container w-100">
                          <label for="quotation_date">Purchase Date</label>
                          <input
                            placeholder=" "
                            className="form-control w-100 "
                            type="date"
                            defaultValue={dayjs().format("YYYY-MM-DD")}
                            id="quotation_date"
                            onChange={(e) =>
                              handleinvoiceDueDate(e, "quotation_date")
                            }
                            name="quotation_date"
                            value={purchaseDateVal}
                          />
                        </div>
                      </div>
                    </Col>
                    {/* <Col md="4">
                      <div className="d-flex align-items-end">
                        <div class="input-container w-100">
                          <input
                            placeholder=" "
                            type="text"
                            id="due_days"
                            name="due_days"
                            onChange={(e) => handleInput(e, "due_days", "")}
                            {...register("due_days")}
                          />
                          <label for="due_days">Due Days</label>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                  {/* </CardBody>
              </Card>
              <Card>
                <CardBody> */}
                  <div className="table-responsive react-table">
                    <TableContainer
                      component={Paper}
                      style={{ minHeight: "300px" }}
                    >
                      <Table sx={{ minWidth: 700 }} stickyHeader>
                        <TableHead className="table-light table-nowrap">
                          <TableRow>
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "product_desc" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("product_desc");
                                }}
                              >
                                Product Description
                              </TableSortLabel>
                            </StyledTableCell>
                            {/* <StyledTableCell align="">
                              <TableSortLabel
                                active={
                                  sortBy == "additional_description"
                                    ? true
                                    : false
                                }
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("additional_description");
                                }}
                              >
                                Additional Description
                              </TableSortLabel>
                            </StyledTableCell> */}
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "unit" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("unit");
                                }}
                              >
                                Qty
                              </TableSortLabel>
                            </StyledTableCell>
                            {/* <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "unit_price" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("unit_price");
                                }}
                              >
                                Unit
                              </TableSortLabel>
                            </StyledTableCell> */}
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "price" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("price");
                                }}
                              >
                                Price
                              </TableSortLabel>
                            </StyledTableCell>
                            <StyledTableCell align="">GST</StyledTableCell>
                            <StyledTableCell align="">Discount %</StyledTableCell>
                            <StyledTableCell align="">
                              <TableSortLabel
                                active={sortBy == "amount" ? true : false}
                                direction={order}
                                className="table-header-column-name"
                                onClick={(e) => {
                                  sortByKey("amount");
                                }}
                              >
                                Amount
                              </TableSortLabel>
                            </StyledTableCell>
                            {dayjs(PodataById.created_at).format("YYYY-MM-DD") ==
                  dayjs().format("YYYY-MM-DD") && 
                            <StyledTableCell align="">Action</StyledTableCell>
}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {voucherDetails?.map((row, index) => {
                            return (
                              <StyledTableRow>
                                <StyledTableCell align="">
                                  <Controller
                                    name="product_id"
                                    control={control}
                                    render={({ field, ref }) => (
                                      <Select
                                        className="js-states form-control"
                                        options={getProductData.data ?? []}
                                        {...field}
                                        ref={ref}
                                        disabled={false}
                                        dropdownHandle={true}
                                        searchable={true}
                                        labelField={"description"}
                                        searchBy="description"
                                        valueField={"id"}
                                        name="product_id"
                                        values={
                                          getProductData?.data?.filter(
                                            (ele) =>
                                              ele.id ==
                                              voucherDetails[index].product_id
                                          ) ?? []
                                        }
                                        onChange={(value) => {
                                          let data = voucherDetails;
                                          console.log(value, "VOUCHERDETAILS1");
                                          data[index].product_id = value[0]?.id;
                                          let product_c = _.filter(
                                            getProductData?.data,
                                            { id: value[0]?.id }
                                          );
                                          console.log(
                                            product_c[0]?.price_category_id,
                                            "product_c"
                                          );
                                          data[index].unit_price =
                                            product_c[0]?.p_rate;
                                          data[index].price_category_id =
                                            product_c[0]?.price_category_id;
                                          data[index].unit = 1;
                                          data[index].tax = product_c[0]?.tax;
                                          setVoucherDetails([
                                            ...data,
                                            {
                                              additional_description: "",
                                              quantity: 0,
                                              unit: "0",
                                              unit_price: 0,
                                              old_price: 0,
                                              amount: 0,
                                              id: index + 1,
                                              product_c: "",
                                            },
                                          ]);
                                        }}
                                      />
                                    )}
                                  />
                                </StyledTableCell>
                                {/* <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      value={row?.additional_description}
                                      id={`narration${index}`}
                                      name={`narration${index}`}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].additional_description =
                                          value;
                                        setVoucherDetails([...data]);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell> */}
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      className="form-control"
                                      value={row?.quantity}
                                      id={`narration${index}`}
                                      name={`narration${index}`}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].quantity = value;
                                        const pro_amt = data[index].unit_price;
                                        const pro_amount = parseFloat(
                                          value * pro_amt
                                        );
                                        data[index].amount =
                                          pro_amount.toFixed(2);
                                        setVoucherDetails([...data]);
                                        calculateAmount(index);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                {/* <StyledTableCell align="">
                                  <Controller
                                    name="unit"
                                    control={control}
                                    render={({ field, ref }) => (
                                      <Select
                                        className="js-states select-control"
                                        // options={stockUnitListData}
                                        {...field}
                                        ref={ref}
                                        dropdownHandle={true}
                                        searchable={true}
                                        labelField={"unit_name"}
                                        valueField={"id"}
                                        name="unit"
                                        // values={
                                        //   stockUnitListData?.filter(
                                        //     (ele) =>
                                        //       ele.id == voucherDetails[index].unit
                                        //   ) ?? []
                                        // }
                                        onChange={(value) => {
                                          let data = voucherDetails;
                                          data[index].unit = value[0]?.id;
                                          setVoucherDetails([...data]);
                                          console.log(value, "products");
                                        }}
                                      />
                                    )}
                                  />
                                </StyledTableCell> */}
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      className="form-control"
                                      id={`narration${index}`}
                                      name={`narration${index}`}
                                      value={row?.unit_price}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].unit_price = value;
                                        const qty = data[index].quantity;
                                        const pro_amount = parseFloat(
                                          value * qty
                                        );
                                        data[index].amount =
                                          pro_amount.toFixed(2);
                                        setVoucherDetails([...data]);
                                        calculateAmount(index);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                {console.log(
                                  voucherDetails[index].tax,
                                  "fksghks"
                                )}
                                <StyledTableCell align="">
                                  <Select
                                    className="js-states form-control"
                                    options={TaxPercentage}
                                    dropdownHandle={true}
                                    searchable={true}
                                    values={
                                      TaxPercentage?.filter(
                                        (ele) =>
                                          ele.value == voucherDetails[index].tax
                                      ) ?? []
                                    }
                                    labelField={"name"}
                                    valueField={"name"}
                                    name={`vat${index}`}
                                    onChange={(value) => {
                                      let data = [...voucherDetails];
                                      data[index].tax = value[0]?.value;
                                      calculateAmount(index);
                                    }}
                                  />
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  <div className="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      className="form-control"
                                      value={row?.discount_val}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        let data = voucherDetails;
                                        data[index].discount_val = value;
                                        calculateAmount(index);
                                      }}
                                    />
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="">
                                  <div class="input-container w-100 m-1">
                                    <input
                                      placeholder=" "
                                      type="text"
                                      className="form-control"
                                      value={row?.amount}
                                      id={`narration${index}`}
                                      name={`narration${index}`}
                                      disabled
                                    />
                                  </div>
                                </StyledTableCell>
                                {dayjs(PodataById.created_at).format("YYYY-MM-DD") ==
                  dayjs().format("YYYY-MM-DD") && 
                                <StyledTableCell
                                  align=""
                                  className="button-items"
                                >
                                  <a
                                    href="javascript:void(0);"
                                    title="Delete"
                                    className="btn btn-danger delete-btn"
                                    onClick={() => {
                                      if (voucherDetails?.length > 1) {
                                        const voucherDetail = _.filter(
                                          voucherDetails,
                                          (key, i) => i != index
                                        );
                                        console.log(
                                          voucherDetail,
                                          "check voucherDetails"
                                        );
                                        setVoucherDetails(voucherDetail);
                                      }
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{ fontSize: 20, color: "black" }}
                                    />
                                    {/* <i className="ri-delete-bin-line"></i> */}
                                  </a>
                                </StyledTableCell>
                          }
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <div className="invoice-width">
                      <div class="input-container w-100">
                        {/* <textarea
                          id="net_amount"
                          rows={3}
                          name="notes"
                          {...register("notes")}
                        />
                        <label for="notes">Notes</label> */}
                      </div>
                    </div>
                    <div className="invoice-width">
                      <div class="input-container w-100">
                        <label for="total_amount">Sub Total Amount</label>
                        <input
                          className="form-control w-100 fs-5"
                          style={{ border: "none", borderBottom: "1px solid" }}
                          placeholder=" "
                          type="text"
                          id="total_amount"
                          name="total_amount"
                          value={totalAmt}
                          disabled
                        />
                      </div>

                      <div className="input-container w-100 d-none">
                        {/* <Controller
                          name="vat"
                          control={control}
                          render={({ field, ref }) => (
                            <Select
                              className="js-states select-control"
                              options={requiredTaxes}
                              {...field}
                              ref={ref}
                              dropdownHandle={true}
                              searchable={true}
                              values={taxType}
                              labelField={"name"}
                              valueField={"name"}
                              name="vat"
                              onChange={(value) => {
                                setTaxType([value[0]]);
                              }}
                            />
                          )}
                        /> */}
                        <input type="text" readOnly></input>
                      </div>
                      <div class="input-container w-100">
                        <label for="vat_amount">GST Amount</label>
                        <input
                          className="form-control w-100 fs-5 "
                          style={{ border: "none", borderBottom: "1px solid" }}
                          placeholder=" "
                          type="text"
                          id="vat_amount"
                          name="vat_amount"
                          disabled
                          value={vat_amount}
                        />
                      </div>
                      <div class="input-container w-100">
                        <label for="discount">Total Discount Amount</label>
                        <input
                          className="form-control w-100 fs-5"
                          style={{ border: "none", borderBottom: "1px solid" }}
                          placeholder=" "
                          type="text"
                          id="discount"
                          name="discount"
                          value={discount_val}
                          readOnly
                        />
                      </div>
                      {/* <div class="input-container w-100 d-none">
                      <label for="freight_charge">Freight Charges</label>
                        <input
                          placeholder=" "
                          className="w-100"
                          type="text"
                          id="freight_charge"
                          name="freight_charge"
                          value={freightCharge}
                          onChange={(e) =>
                            setfreightCharge(
                              e.target.value != "" ? e.target.value : "0"
                            )
                          }
                        />
                        
                      </div> */}
                      <div class="input-container w-100">
                        <label for="round_off">Round Off</label>
                        <input
                          className="form-control w-100 fs-5"
                          style={{ border: "none", borderBottom: "1px solid" }}
                          placeholder=" "
                          type="text"
                          id="round_off"
                          name="round_off"
                          value={roundOffAmt}
                          onChange={(e) => setroundOffAmt(e.target.value)}
                          readOnly
                        />
                      </div>
                      <div class="input-container w-100">
                        <label for="net_amount">Net Amount</label>
                        <input
                          placeholder=" "
                          className="form-control w-100 fs-5"
                          style={{ border: "none", borderBottom: "1px solid" }}
                          type="text"
                          id="net_amount"
                          name="net_amount"
                          disabled
                          // value={netAmount}
                          {...register("net_amount")}
                        />
                      </div>

                      {errors?.net_amount?.message && (
                        <p className="error">{errors.net_amount.message}</p>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="text-end">
                {dayjs(PodataById.created_at).format("YYYY-MM-DD") ==
                  dayjs().format("YYYY-MM-DD") && (
                  <Button
                    color="primary"
                    onClick={() => console.log("hello")}
                    type="submit"
                  >
                    Save
                  </Button>
                )}
              </div>
            </form>
          </div>
        </section>
      </main>
    </>
  );
};
export default AddPurchaseInvoice;
