import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useDispatch, useSelector } from "react-redux";
import { creditDueDataRequest } from "../../store/order/order.action";
import { orderSelector } from "../../store/order/order.model";
import { getPurchaseReminderDataRequest } from "../../store/PurchaseCompany/pc.action";
import { companySelector } from "../../store/PurchaseCompany/pc.model";
import { InventrySelector } from "../../store/InventryScreen/Selector";
import { Table } from "react-bootstrap";
import { getProductRequest } from "../../store/product/product.action";
import { getInventryDataRequest } from "../../store/InventryScreen/Action";
import { createInventryPayload } from "../../utils/config";

export const InventoryReminder = () => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
 
  const [searchQuery, setSearchQuery] = useState("");
  const { getInventData } = useSelector(InventrySelector);
  console.log(getInventData,'kyfjhl');
  
  /* useEffect */
  useEffect(() => {
    console.log("kjfjf");

    const payload1 = {
        page:0,
        limit:0,
        query : "",
        productid : "",
        minqty :"",
        maxqty :99,
        orderStatus : "",
    }
    dispatch(getInventryDataRequest(payload1));
  
  }, [rowsPerPage, currentPage, searchQuery]);
  return (
    <div>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">
                Inventory Reminder Below 100 stocks
              </h2>
              <p>Important insights of your business here</p>
            </div>
          </div>
          <div className="card mb-4">
            {/* <header className="card-header">
              <h4 className="card-title">Due Days Exceeded List</h4>
            </header> */}
            <div className="card-body">
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Product</th>

                      <th>Current Qty</th>
                      <th>Inward Qty</th>

                      <th>Stock</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getInventData &&
                      getInventData.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item?.products?.name}</td>
                          {/* <td>{item?.opening_qty}</td> */}
                          <td>{item?.current_qty}</td>
                          <td>{item?.inward_qty}</td>
                          {/* <td>{item?.outward_qty}</td> */}
                          <td> <span className={`badge rounded-pill alert-${
                          item?.current_qty <=0
                              ? "danger"
                              : "success"
                          }`}>
                                    {item?.current_qty <=0 ?"Out Of Stock":"In Stock"}

                          </span></td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
