import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { get, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { InventrySelector } from "../../store/InventryScreen/Selector";
import { InventryCreateValidator } from "../../validators/Validators";
import { productSelector } from "../../store/product/product.model";
import {
  getInventryDataRequest,
  postInventryDataRequest,
  updateInventryDataRequest,
} from "../../store/InventryScreen/Action";
import { getProductRequest } from "../../store/product/product.action";
import { nothing, selectDefault } from "../../utils/Constants";
import { createInventryPayload } from "../../utils/config";
import { authSelector } from "../../store/auth/auth.action";
import { userSelector } from "../../store/user/user.action";

const InventoryPage = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(InventryCreateValidator),
  });
  console.log(errors, "56789sj");
  // Selectors
  const {
    getInventData,
    postSuccess,
    updateSuccess,
    deleteSuccess,
    getInventryCount,
  } = useSelector(InventrySelector);
  const { getProductData } = useSelector(productSelector);
  console.log(getProductData, "getProductData");

  console.log(getInventData, "getInventData");
  const { userData, screenData } = useSelector(userSelector);
  // States
  const screen = screenData?.response?.screen || {};

  console.log(screen, "sjdks");
  const [id, setId] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const startIndex = (page - 1) * rowsPerPage;
  // const [currentPage, setCurrentPage] = useState(1);

  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  /* Function */
  const totalPages = Math.ceil(getInventryCount / rowsPerPage) || 1;
  const handlePageChange = (pageNumber) => {
    // setCurrentPage(pageNumber);
    setPage(pageNumber);
    console.log(currentPage, "kjfjf");
  };
    // find endindex
    let endIndex = [page] * rowsPerPage;
    if (endIndex > getProductData?.count) {
      endIndex = getProductData?.count;
    }
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const onSubmitHandler = (formData) => {
    delete formData.opening_qty;
    delete formData.outward_qty;
    delete formData.unit_type;
    if (id === 0) {
      dispatch(postInventryDataRequest(formData));
    } else {
      dispatch(updateInventryDataRequest({ formData, id }));
    }
  };
  const onPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleEdit = (val) => {
    setId(val.id);
    reset({
      product_id: val.product_id,
      opening_qty: val.opening_qty,
      current_qty: val.current_qty,
      inward_qty: "",
      outward_qty: "",
    });
  };
  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages ? currentPage + 1 : null;
  const nextPage2 =
    totalPages != currentPage
      ? currentPage < totalPages && currentPage > 3
        ? currentPage + 2
        : null
      : null;
  const lastPage_before =
    currentPage > 3 && totalPages != currentPage ? "..." : null;
  const lastPage =
    currentPage > 3 && totalPages != currentPage ? totalPages : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);

  //   const handleDelete = (id) => {
  //     dispatch(deleteInventryDataRequest(id));
  //   };

  useEffect(() => {
    console.log("kjfjf");

    const payload1 = createInventryPayload(
      currentPage,
      rowsPerPage,
      searchQuery
    );
    dispatch(getInventryDataRequest(payload1));
    const payload = {
      query: "",
      prod_id: "",
      page: "",
      limit: "",
    };
    dispatch(getProductRequest(payload));
  }, [rowsPerPage, currentPage, searchQuery]);

  useEffect(() => {
    if (postSuccess || updateSuccess || deleteSuccess) {
      const payload1 = createInventryPayload(
        currentPage,
        rowsPerPage,
        searchQuery
      );
      dispatch(getInventryDataRequest(payload1));
      setId(0);
      reset({
        product_id: "",
        opening_qty: "",
        current_qty: "",
        inward_qty: "",
        outward_qty: "",
      });
    }
  }, [postSuccess, updateSuccess, deleteSuccess, dispatch, reset]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div className="col-md-6">
              <h2 className="content-title card-title">Inventory Management</h2>
              <p>Add, edit or delete inventory items</p>
            </div>
            <div className="col-md-2 col-2  text-center">
              <span className="font-md color-gray-900 font-medium border-1-right span">
                <h6>
                  {" "}
                  {getInventryCount > endIndex ? `Showing ${startIndex}- ${endIndex} of ${getInventryCount} result`:`Showing ${startIndex}-  ${getInventryCount} of ${getInventryCount} result`}

                </h6>
              </span>
            </div>
            <div className="col-md-3">
              <input
                className="form-control bg-white"
                type="text"
                placeholder="Search Inventory"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={3}>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="mb-4">
                      <label className="form-label">Product Name</label>
                      <select
                        {...register("product_id")}
                        className="form-control"
                      >
                        <option>SELECT</option>
                        {getProductData?.data?.map((product) => (
                          <option key={product.id} value={product.id}>
                            {product.name}
                          </option>
                        ))}
                      </select>
                      {errors.product_id && (
                        <p className="text-danger">
                          {errors.product_id.message}
                        </p>
                      )}
                    </div>
                    {/* <div className="mb-4">
                      <label className="form-label">Opening Quantity</label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("opening_qty")}
                      />
                      {errors.opening_qty && (
                        <p className="text-danger">
                          {errors.opening_qty.message}
                        </p>
                      )}
                    </div> */}
                    <div className="mb-4">
                      <label className="form-label">Current Quantity</label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("current_qty")}
                      />
                      {errors.current_qty && (
                        <p className="text-danger">
                          {errors.current_qty.message}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Inward Quantity</label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("inward_qty")}
                      />
                      {errors.inward_qty && (
                        <p className="text-danger">
                          {errors.inward_qty.message}
                        </p>
                      )}
                    </div>
                    {/* <div className="mb-4">
                      <label className="form-label">Outward Quantity</label>
                      <input
                        type="number"
                        defaultValue="0"
                        className="form-control"
                        {...register("outward_qty")}
                      />
                    </div> */}

                    <div className="d-grid">
                      {/* <button className="btn btn-primary" type="submit">
                        {id === 0 ? "Create Inventory" : "Update Inventory"}
                      </button> */}
                      {id === 0
                        ? screen?.["Inventory-add"] && (
                            <button className="btn btn-primary" type="submit">
                              Create Inventory
                            </button>
                          )
                        : screen?.["Inventory-edit"] && (
                            <button className="btn btn-primary" type="submit">
                              Update Inventory
                            </button>
                          )}
                    </div>
                  </form>
                </Col>

                <Col md={9}>
                  <div className="table-responsive">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Product</th>
                          {/* <th>Opening Qty</th> */}
                          <th>Current Qty</th>
                          <th>Inward Qty</th>
                          {/* <th>Outward Qty</th> */}
                          <th>Created At</th>
                          <th>Updated At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getInventData &&
                          getInventData.map((item, index) => (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item?.products?.name}</td>
                              {/* <td>{item?.opening_qty}</td> */}
                              <td>{item?.current_qty}</td>
                              <td>{item?.inward_qty}</td>
                              {/* <td>{item?.outward_qty}</td> */}
                              <td>
                                {dayjs(item?.created_at).format("DD-MM-YY")}
                              </td>
                              <td>
                                {dayjs(item?.updated_at).format("DD-MM-YY")}
                              </td>
                              <td>
                                {screen?.["Inventory-edit"] && (
                                  <button
                                    className="btn btn-light"
                                    onClick={() => handleEdit(item)}
                                  >
                                    Edit
                                  </button>
                                )}
                                {/* {screen?.["Inventory-delete"] && (
                                  <button
                                    className="btn btn-danger"
                                    // onClick={() => handleDelete(item.id)}
                                  >
                                    Delete
                                  </button>
                                )} */}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <div className="pagination-area mt-15 mb-50">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      <li
                        className={`page-item a ${
                          page === 1 ? "disabled" : ""
                        }`}
                      >
                        <a
                          className="page-link page-prev"
                          onClick={() => onPageChange(1)}
                        >
                          First
                        </a>
                      </li>
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="material-icons md-chevron_left"></i>
                        </a>
                      </li>

                      {[...Array(totalPages)]
                        ?.map((_, index) => index + 1)
                        .filter((page) => {
                          const startPage = Math.max(1, currentPage - 2);
                          const endPage = Math.min(totalPages, currentPage + 2);
                          return page >= startPage && page <= endPage;
                        })
                        .map((page) => (
                          <li
                            key={page}
                            className={`page-item ${
                              currentPage === page ? "active" : ""
                            }`}
                            onClick={() => handlePageChange(page)}
                          >
                            <a className="page-link" href="#">
                              {page}
                            </a>
                          </li>
                        ))}

                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="material-icons md-chevron_right"></i>
                        </a>
                      </li>
                      <li
                        className={`page-item ${
                          page === totalPages ? "disabled" : ""
                        }`}
                      >
                        <a
                          className="page-link page-next"
                          onClick={() => onPageChange(totalPages)}
                        >
                          Last
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Row>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default InventoryPage;
