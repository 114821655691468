import { Link, useNavigate } from "react-router-dom";
import logo from "../../src/assets/imgs/sikan-logo.png";
import { clearUserLogout } from "../store/auth/auth.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { orderSelector } from "../store/order/order.model";
import getUserInfo from "../store/auth/auth.selector";
import { authSelector } from "../store/auth/auth.action";
import {
  getIdDataRequest,
  getIdDataScreenRequest,
  getUserDataRequest,
  userSelector,
} from "../store/user/user.action";

function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = localStorage.getItem("id");
  const role = localStorage.getItem("role");
  // const { screen } = useSelector(authSelector);
  const { updateSuccess, userData, idData, screenData } =
    useSelector(userSelector);
  console.log(screenData, "kfjdjk");

  const [order, setOrder] = useState({ display: "none" });
  const [reminder, setReminder] = useState({ display: "none" });
  const [dashboard, setDashboard] = useState({ display: "none" });
  const [product, setProduct] = useState({ display: "none" });
  const [inventory, setInventory] = useState({ display: "none" });
  const handleKeyPress = (event) => {
    // console.log(event.altKey,'lhkghdskh');
    if (event.altKey == true) {
      event.key === "s" && window.open("/pageorders/add");
      event.key === "p" && window.open("/product/add");
      event.key === "v" && window.open("/vendor/add");
    }
  };
  useEffect(() => {
    if (updateSuccess) {
      console.log("dghkjsk");

      dispatch(getIdDataScreenRequest(userId));
    }
  }, [updateSuccess]);
  useEffect(() => {
    dispatch(getIdDataScreenRequest(userId));

    document.addEventListener("keydown", handleKeyPress);
  }, []);
  console.log(screenData?.response?.screen, "dfahk");

  const screen = screenData?.response?.screen || {};
  console.log(screen, "dtytyhdf");

  return (
    <>
      {" "}
      <div className="screen-overlay"></div>{" "}
      <aside className="navbar-aside" id="offcanvas_aside">
        {" "}
        <div className="aside-top">
          <Link className="brand-wrap" to="/">
            <img className="logo" src={logo} alt="Evara Dashboard" />
          </Link>
          <div>
            <button className="btn btn-icon btn-aside-minimize">
              <i className="text-muted material-icons md-menu_open"></i>
            </button>
          </div>
        </div>{" "}
        <nav>
          {" "}
          <ul className="menu-aside">
            <li
              className="menu-item"
              onMouseOver={() => setDashboard({})}
              onMouseOut={() => setDashboard({ display: "none" })}
            >
              <Link className="menu-link" to="/">
                <i className="icon material-icons md-home"></i>
                <span className="text">Dashboard</span>
              </Link>
              <ul className="menu-aside mt-0 mb-0" style={dashboard}>
                {screen?.["dashboard-orderDashboard"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/dashboardOrder">
                      <i className="icon material-icons md-production_quantity_limits"></i>
                      <span className="text">Order Dashboard</span>
                    </Link>
                  </li>
                )}
                {screen?.["dashboard-purchaseDashboard"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/dashboardPurchase">
                      <i className="icon material-icons md-dashboard "></i>
                      <span className="text">Purchase Dashboard</span>
                    </Link>
                  </li>
                )}

                {screen?.["dashboard-paymentDashboard"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/dashboardPayment">
                      <i className="icon material-icons md-payment "></i>
                      <span className="text">Payment Dashboard</span>
                    </Link>
                  </li>
                )}
              </ul>
            </li>

            <li
              className="menu-item"
              onMouseOver={() => setReminder({})}
              onMouseOut={() => setReminder({ display: "none" })}
            >
              <Link className="menu-link">
                <i className="icon material-icons md-notifications"></i>
                <span className="text">Reminder</span>
              </Link>
              <ul className="menu-aside mt-0 mb-0" style={reminder}>
                {screen?.["reminder-orderReminder"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/reminder/ordersales">
                      <i className="icon material-icons md-notifications"></i>
                      <span className="text">Order Reminder</span>
                    </Link>
                  </li>
                )}

                {screen?.["reminder-saleReminder"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/reminder/salesdrop">
                      <i className="icon material-icons md-notifications"></i>
                      <span className="text">Sales Drop Reminder</span>
                    </Link>
                  </li>
                )}
                {screen?.["reminder-payreminder"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/reminder/weelypay">
                      <i className="icon material-icons md-notifications"></i>
                      <span className="text">Weekly Pay Reminder</span>
                    </Link>
                  </li>
                )}
                {screen?.["reminder-purcahsereminder"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/reminder/purchase">
                      <i className="icon material-icons md-notifications"></i>
                      <span className="text">Purchase Reminder</span>
                    </Link>
                  </li>
                )}
                {screen?.["reminder-inventoryreminder"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/reminder/inventory">
                      <i className="icon material-icons md-notifications"></i>
                      <span className="text">Inventory Reminder</span>
                    </Link>
                  </li>
                )}
              </ul>
            </li>
            {(screen?.["categories-add"] === "true" ||
              screen?.["categories-edit"] === "true" ||
              screen?.["categories-delete"] === "true") && (
              <li className="menu-item">
                <Link className="menu-link" to="/pagecategories">
                  <i className="icon material-icons md-category"></i>
                  <span className="text">Categories</span>
                </Link>
              </li>
            )}
            {(screen?.["brand-add"] === "true" ||
              screen?.["brand-edit"] === "true" ||
              screen?.["brand-delete"] === "true") && (
              <li className="menu-item">
                <Link className="menu-link" to="/pagebrands">
                  <i className="icon material-icons md-loyalty"></i>
                  <span className="text">Brands</span>
                </Link>
              </li>
            )}

            {(screen?.["product-add"] === "true" ||
              screen?.["product-edit"] === "true" ||
              screen?.["product-delete"] === "true") && (
              <li className="menu-item">
                <Link className="menu-link" to="/pageproductslist">
                  <i className="icon material-icons md-shopping_bag"></i>
                  <span className="text">Products</span>
                </Link>
              </li>
            )}
            {screen?.["profitMargin-add"] === "true" && (
              <li className="menu-item">
                <Link className="menu-link" to="/profitmargin">
                  <i className="icon material-icons md-attach_money"></i>
                  <span className="text">Profit Margin</span>
                </Link>
              </li>
            )}

            <li className="menu-item">
              {/* <Link className="menu-link" to="/pageformprofit">
                <i className="icon material-icons md-monetization_on"></i>
                <span className="text">Set Profit</span>
              </Link> */}
              {/* <div className="submenu">
                <a href="page-transactions-1.html">Transaction 1</a>
                <a href="page-transactions-2.html">Transaction 2</a>
                <a href="page-transactions-details.html">Transaction Details</a>
              </div> */}
            </li>

            {screen?.["order-add"] === "true" && (
              <li className="menu-item">
                <Link className="menu-link" to="/pageorders">
                  <i className="icon material-icons md-shopping_cart"></i>
                  <span className="text">Orders</span>
                </Link>
                {/* <ul className="menu-aside mt-0 mb-0"style={order}>
                  <li className="menu-item" >
                  <Link className="menu-link" to="/order/report">
                    <i className="icon material-icons md-assessment"></i>
                    <span className="text">Orders Report</span>
                  </Link>
                </li>
                  </ul> */}
              </li>
            )}
            {screen?.["quotation-add"] === "true" && (
              <li className="menu-item">
                <Link className="menu-link" to="/pagecotation">
                  <i className="icon material-icons md-add_shopping_cart "></i>
                  <span className="text">Quotation</span>
                </Link>
              </li>
            )}

            <li
              className="menu-item"
              onMouseOver={() => setOrder({})}
              onMouseOut={() => setOrder({ display: "none" })}
            >
              <Link className="menu-link">
                <i className="icon material-icons md-account_balance_wallet"></i>
                <span className="text">Payment</span>
              </Link>
              <ul className="menu-aside mt-0 mb-0" style={order}>
                {screen?.["order-add"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/payment/orderpaymentlist">
                      <i className="icon material-icons md-monetization_on"></i>
                      <span className="text">Order Payment</span>
                    </Link>
                  </li>
                )}
                {screen?.["purchasePayment-add"] === "true" && (
                  <li className="menu-item">
                    <Link
                      className="menu-link"
                      to="/payment/purchasepaymentlist"
                    >
                      <i className="icon material-icons md-monetization_on"></i>
                      <span className="text">Purchase Payment</span>
                    </Link>
                  </li>
                )}
              </ul>
            </li>

            {(screen?.["vendor-add"] === "true" ||
              screen?.["vendor-edit"] === "true" ||
              screen?.["vendor-delete"] === "true") && (
              <li className="menu-item">
                <Link className="menu-link" to="/pagesellerslist">
                  <i className="icon material-icons md-store"></i>
                  <span className="text">Vendors</span>
                </Link>
              </li>
            )}

            {(screen?.["users-add"] === "true" ||
              screen?.["users-edit"] === "true" ||
              screen?.["users-delete"] === "true" ||
              role == 2) && (
              <li className="menu-item">
                <Link className="menu-link" to="/pageusers">
                  <i className="icon material-icons md-person"></i>
                  <span className="text">Users</span>
                </Link>
              </li>
            )}

            {(screen?.["purchaseCompany-add"] === "true" ||
              screen?.["purchaseCompany-edit"] === "true" ||
              screen?.["purchaseCompany-delete"] === "true") && (
              <li className="menu-item">
                <Link className="menu-link" to="/purchanseCompanyList">
                  <i className="icon material-icons md-format_list_bulleted"></i>

                  <span className="text">Purchase Company List</span>
                </Link>
              </li>
            )}
            {/* {(screen
            ?.purchaseInvoice
                ?.add || screen
                    ?.purchaseInvoice
                        ?.edit || screen
                            ?.purchaseInvoice
                                ?.delete) && (
              <li className="menu-item">
                <li className="menu-item">
                    <Link className="menu-link" to="/pageusers">
                        <i className="icon material-icons md-person"></i>
                        <span className="text">Users</span>
                    </Link>
                </li>)
                <li className="menu-item">
                    <Link className="menu-link" to="/purchanseCompanyList">
                        <i className="icon material-icons md-format_list_bulleted"></i>

                        <span className="text">Purchase Company List</span>
                    </Link>
                </li> */}
            {screen?.["purchaseInvoice-add"] === "true" && (
              <li className="menu-item">
                <Link className="menu-link" to="/purchanseInovice">
                  <i className="icon material-icons md-receipt"></i>
                  <span className="text">Purchase Invoice</span>
                </Link>
              </li>
            )}

            {(screen?.["Inventory-add"] === "true" ||
              screen?.["Inventory-edit"] === "true" ||
              screen?.["Inventory-delete"] === "true") && (
              <li className="menu-item">
                <Link className="menu-link" to="/inventory">
                  <i className="icon material-icons md-archive"></i>
                  <span className="text">Inventory</span>
                </Link>
              </li>
            )}

            {screen?.["support-add"] === "true" && (
              <li className="menu-item">
                <Link className="menu-link" to="/support">
                  <i className="icon material-icons md-help"></i>
                  <span className="text">Support</span>
                </Link>
              </li>
            )}

            <li
              className="menu-item"
              onMouseOver={() => setInventory({})}
              onMouseOut={() => setInventory({ display: "none" })}
            >
              <Link className="menu-link">
                <i className="icon material-icons md-assessment"></i>
                <span className="text">Report</span>
              </Link>
              <ul className="menu-aside mt-0 mb-0" style={inventory}>
                {screen?.["report-inventoryReport"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/inventory/report">
                      <i className="icon material-icons md-archive"></i>
                      <span className="text">Inventory Report</span>
                    </Link>
                  </li>
                )}

                {screen?.["report-orderReport"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/order/report">
                      <i className="icon material-icons md-shopping_cart"></i>
                      <span className="text">Orders Report</span>
                    </Link>
                  </li>
                )}

                {screen?.["report-purchaseReport"] === "true" && (
                  <li className="menu-item">
                    <Link className="menu-link" to="/pusrchase/report">
                      <i className="icon material-icons md-format_list_bulleted"></i>
                      <span className="text">Purchase Report</span>
                    </Link>
                  </li>
                )}
              </ul>
            </li>
            {/* <li className="menu-item has-submenu">
              <a className="menu-link" href="page-form-product-1.html">
                <i className="icon material-icons md-add_box"></i>
                <span className="text">Add product</span>
              </a>
              <div className="submenu">
                <a href="page-form-product-1.html">Add product 1</a>
                <a href="page-form-product-2.html">Add product 2</a>
                <a href="page-form-product-3.html">Add product 3</a>
                <a href="page-form-product-4.html">Add product 4</a>
              </div>
            </li> */}
            {/* <li className="menu-item has-submenu">
              <a className="menu-link" href="#">
                <i className="icon material-icons md-person"></i>
                <span className="text">Account</span>
              </a>
              <div className="submenu">
                <a href="page-account-login.html">User login</a>
                <a href="page-account-register.html">User registration</a>
                <a href="page-error-404.html">Error 404</a>
              </div>
            </li> */}
            {/* <li className="menu-item">
              <a className="menu-link" href="page-reviews.html">
                <i className="icon material-icons md-comment"></i>
                <span className="text">Reviews</span>
              </a>
            </li>
            <li className="menu-item">
              <a className="menu-link" href="page-brands.html">
                <i className="icon material-icons md-stars"></i>
                <span className="text">Brands</span>
              </a>
            </li>
            <li className="menu-item">
              <a className="menu-link" disabled="" href="#">
                <i className="icon material-icons md-pie_chart"></i>
                <span className="text">Statistics</span>
              </a>
            </li> */}
          </ul>{" "}
          <hr />{" "}
          <ul className="menu-aside">
            <li className="menu-item">
              <div
                className="menu-link"
                onClick={() => {
                  localStorage.clear();
                  dispatch(clearUserLogout());
                  navigate("/login");
                }}
              >
                <i className="material-icons md-exit_to_app"></i>
                <span className="text">Logout</span>
              </div>
              {/* <div className="submenu">
                <a href="page-settings-1.html">Setting sample 1</a>
                <a href="page-settings-2.html">Setting sample 2</a>
              </div> */}
            </li>
            {/* <li className="menu-item">
              <a className="menu-link" href="page-blank.html">
                <i className="icon material-icons md-local_offer"></i>
                <span className="text"> Starter page</span>
              </a>
            </li> */}
          </ul>{" "}
        </nav>
      </aside>{" "}
    </>
  );
}
export default Sidebar;
