// Enternal Imports
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { read, utils, writeFile } from "xlsx";
// Internal Imports
import dataexcel from "../../assets/excel/ExcelSample.xlsx";

import {
  deleteProductRequest,
  getProductPriceRequest,
  getProductRequest,
  postBulkProductRequest,
  productStateResetRequest,
} from "../../store/product/product.action";
import { productSelector } from "../../store/product/product.model";
import { API_BASE } from "../../services/config";
import noImg from "../../assets/imgs/no-image.jpg";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getCatRequest } from "../../store/category/category.action";
import { getBrandRequest } from "../../store/brand/brand.action";
import { BrandSelector } from "../../store/brand/brand.model";
import { CatAddSelector } from "../../store/category/category.model";
import { keyMapping } from "../../utils/Constants";
import { authSelector } from "../../store/auth/auth.action";
import noimg from "../../assets/imgs/no-image.jpg";
import { SlideshowLightbox } from "lightbox.js-react";
import { userSelector } from "../../store/user/user.action";
function Pageproductslist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Selectors */
  const { getProductData, productDeleteSucces, cat, productBulkSendSuccess } =
    useSelector(productSelector);
  const { getBrandData } = useSelector(BrandSelector);
  const { getCatData } = useSelector(CatAddSelector);
  console.log(cat, "opop");
  // const { screen } = useSelector(authSelector);
  // const data=screen
  const { userData, screenData } = useSelector(userSelector);

  // const data=screen
  // const screen = Array.isArray(userData?.data) && userData?.data[0]?.screen || {};
  const screen = screenData?.response?.screen || {};
  console.log(screen, "123456");

  console.log(getCatData?.data, getProductData.count, "34567");
  /* State */
  // const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [errors, setErrors] = useState({});
  const [importErr, setImportErr] = useState({ err: false, msg: "" });
  const [importSuccess, setImportSuccess] = useState({
    success: false,
    msg: "",
  });
  const [array, setArray] = useState([]);

  console.log(array, errors, "valuesdfs");
  console.log(importErr, importSuccess, errors, "imordkf");
  /* Function */
  const totalPages1 = Math.ceil(getProductData?.count / rowsPerPage) || 1;
  const startIndex = (page - 1) * rowsPerPage;
  let endIndex = [page] * rowsPerPage;
  if (endIndex > getProductData?.count) {
    endIndex = getProductData?.count;
  }
  const handleDelete = (id) => {
    dispatch(deleteProductRequest(id));
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const onPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages1) {
      setPage(newPage);
    }
  };
  const currentPage = page; // Replace with your actual current page state
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  const handleClose = () => {
    setShow(false);
    setErrors({});
    setImportErr({ err: false, msg: "" });
  };
  const handleShow = () => setShow(true);
  const handleUplad = () => {
    let payload = {
      datas: array,
    };
    dispatch(postBulkProductRequest(payload));
  };
  const isFound = (list, check) => {
    console.log(list, check, "lisdfdfst");
    console.log(check);
    let checked = list.filter((element) => {
      if (element.category === check || element.name === check) {
        console.log(element.id);
        return element.id;
      }
      return false;
    });
    console.log(checked, "checked");
    return checked;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const fileBuffer = await file.arrayBuffer();
    const workbook = read(fileBuffer, {
      type: "string",
      raw: true,
      dateNF: "dd/MM/yyyy",
    });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = utils.sheet_to_json(worksheet, { raw: false });

    setErrors({});
    setImportErr({ err: false, msg: "" });

    const newErrors = {};
    console.log(data, "rghjuygh");

    const validateField = (item, field, row, errorKey, errorMsg) => {
      console.log(field, item, "wertygb");

      if (!item[field]) {
        // Accumulate errors for each missing field
        console.log(item[field], "hdkhfdu");

        newErrors[errorKey] = newErrors[errorKey] || [];
        newErrors[errorKey].push(`${errorMsg} in row ${row}`);
        return false;
      }
      return true;
    };
    console.log(validateField, "rghjuygh");

    const arrayObj = data
      .map((item, i) => {
        const row_no = i + 2;
        console.log(item["Price Category"], "gfg");

        if (
          !validateField(
            item,
            "Product Name",
            row_no,
            "productName",
            "Product Name is missing"
          )
        )
          return null;
        if (
          !validateField(
            item,
            "Product Description",
            row_no,
            "productDescription",
            "Product Description is missing"
          )
        )
          return null;
        if (!validateField(item, "Tax", row_no, "tax", "Tax is missing"))
          return null;
        // if (
        //   !validateField(
        //     item,
        //     "Price Category",
        //     row_no,
        //     "priceCategory",
        //     "Price Category is missing"
        //   )
        // )
        //   return null;
        if (
          !validateField(
            item,
            "P Price",
            row_no,
            "pPrice",
            "P Price is missing"
          )
        )
          return null;
        if (
          !validateField(
            item,
            "C Price",
            row_no,
            "cPrice",
            "C Price is missing"
          )
        )
          return null;
        if (
          !validateField(
            item,
            "MSR Price",
            row_no,
            "msrPrice",
            "MRP Price is missing"
          )
        )
          return null;
        if (
          !validateField(
            item,
            "W Price",
            row_no,
            "wPrice",
            "W Price is missing"
          )
        )
          return null;

        const PriceCategroyCheck = isFound(cat, item["Price Category"]);
        if (PriceCategroyCheck.length === 0) {
          newErrors.priceCategory = newErrors.priceCategory || [];
          newErrors.priceCategory.push(
            `Price Category Is Missing in row ${row_no}`
          );
          return null;
        }
        const priceCategoryid = PriceCategroyCheck[0].id;
        console.log(PriceCategroyCheck, "priceafjsd");

        const categoryCheck = isFound(getCatData?.data, item["Category Name"]);
        if (categoryCheck.length === 0) {
          newErrors.categoryName = newErrors.categoryName || [];
          newErrors.categoryName.push(`Invalid Category Name in row ${row_no}`);
          return null;
        }

        const categoryId = categoryCheck[0].id;

        const brandCheck = isFound(getBrandData?.data, item["Brand Name"]);
        if (brandCheck.length === 0) {
          newErrors.brandName = newErrors.brandName || [];
          newErrors.brandName.push(`Invalid Brand Name in row ${row_no}`);
          return null;
        }
        const brandId = brandCheck[0].id;

        const transformedItem = {
          ...item,
          brand_id: brandId,
          catgory_id: categoryId,
          price_category_id: priceCategoryid,
        };

        const formattedItem = Object.keys(transformedItem).reduce(
          (acc, key) => {
            const newKey = keyMapping[key] || key;
            if (
              newKey !== "Category Name" &&
              newKey !== "Brand Name" &&
              newKey !== "Price Category"
            ) {
              acc[newKey] = transformedItem[key];
            }
            return acc;
          },
          {}
        );

        return formattedItem;
      })
      .filter((item) => item !== null);

    if (Object.keys(newErrors).length > 0) {
      event.target.value = null;
      console.log(newErrors, "khkhkh");

      setErrors(newErrors);
      setImportErr({ err: true, msg: "Errors found in the file" });
    } else {
      setImportSuccess({ success: true, msg: "Validation success!" });
      setArray(arrayObj);
    }

    console.log(arrayObj, "Transformed array of objects");
  };

  /* useEffect */
  useEffect(() => {
    const payload = {
      query: searchQuery,
      prod_id: 0,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getProductRequest(payload));

    const payload1 = {
      query: "",
      page: 0,
      limit: 0,
    };
    dispatch(getCatRequest(payload1));
    const payload2 = {
      query: "",
      page: 0,
      limit: 0,
    };
    dispatch(getBrandRequest(payload2));
    dispatch(getProductPriceRequest());
  }, [searchQuery, currentPage, rowsPerPage]);
  useEffect(() => {
    if (productDeleteSucces) {
      const payload = {
        query: "",
        prod_id: 0,
        page: currentPage,
        limit: rowsPerPage,
      };
      dispatch(getProductRequest(payload));
      dispatch(productStateResetRequest());
    }
    if (productBulkSendSuccess) {
      handleClose();
      setArray([]);
      const payload = {
        query: "",
        prod_id: 0,
        page: currentPage,
        limit: rowsPerPage,
      };
      dispatch(getProductRequest(payload));
      dispatch(productStateResetRequest());
    }
  }, [productDeleteSucces, productBulkSendSuccess]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header row">
            <div className="col-8">
              <h2 className="content-title card-title">Products List</h2>
              <p>Add, edit or delete a Product </p>
            </div>
            <div className="col-2 text-end">
              {screen?.["product-add"] === "true" && (
                <Button variant="primary" onClick={handleShow}>
                  Bulk Upload
                </Button>
              )}

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Bulk Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex flex-column ">
                    <label className="mb-2"> Download Sample file</label>

                    <a href={dataexcel} className="mb-2" download>
                      <button className="btn btn-primary">
                        Download Excel File
                      </button>
                    </a>
                    <label className="mb-2"> Upload file</label>

                    <input
                      className="form-control mb-2"
                      type="file"
                      onChange={handleFileUpload}
                      accept=".xlsx, .xls"
                    />
                    <p className="text-danger">
                      {importErr && importErr.err == true ? importErr.msg : ""}
                    </p>
                    <p className="text-danger">{errors.brandName}</p>
                    <p className="text-danger">{errors.productName}</p>
                    <p className="text-danger">{errors.productDescription}</p>
                    <p className="text-danger">{errors.priceCategory}</p>
                    <p className="text-danger">{errors.pPrice}</p>
                    <p className="text-danger">{errors.cPrice}</p>
                    <p className="text-danger">{errors.msrPrice}</p>
                    <p className="text-danger">{errors.wPrice}</p>
                    <p className="text-danger">{errors.categoryName}</p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleUplad}>
                    Upload
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="col-2 text-end">
              {/* <a className="btn btn-light rounded font-md" href="#">
                Export
              </a>
              <a className="btn btn-light rounded font-md" href="#">
                Import
              </a> */}
              {screen?.["product-add"] === "true" && (
                <button
                  className="btn btn-primary btn-sm rounded"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(productStateResetRequest());
                    navigate("/product/add");
                  }}
                >
                  {" "}
                  Create Product
                </button>
              )}
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  {/* <div className="form-check ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div> */}
                </div>
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  {/* <select className="form-select">
                    <option selected="">All category</option>
                    <option>Switches</option>
                    <option>Adapter</option>
                    <option>Panels</option>
                    <option>Wires</option>
                  </select> */}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-md-2 col-6 text-center">
                  {/* <h4> Showing {startIndex}&ndash;{endIndex} of{" "}
                 {getProductData?.count} results</h4> */}
                  <span className="font-md color-gray-900 font-medium border-1-right span">
                    <h6>
                      {" "}
                      Showing {startIndex}&ndash;{endIndex} of{" "}
                      {getProductData?.count} results
                    </h6>
                  </span>
                </div>
                {/* <div className="col-md-2 col-6">
                  <select className="form-select">
                    <option selected="">Status</option>
                    <option>Active</option>
                    <option>Disabled</option>
                    <option>Show all</option>
                  </select>
                </div> */}

                <div className="col-md-2 col-6">
                  <select
                    className="form-select"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value={10}>Show 10</option>
                    <option value={20}>Show 20</option>
                    <option value={30}>Show 30</option>
                  </select>
                </div>
              </div>
            </header>

            <div className="card-body">
              <article className="itemlist">
                <div className="row align-items-center">
                  {/* <div className="col col-check flex-grow-0">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-2 col-sm-2 col-4 col-price   ">
                    <h6 className="mb-0">Image</h6>
                  </div>

                  <div className="col-lg-3 col-sm-4 col-4 col-name flex-grow-1 px-4 ">
                    <h6 className="mb-0 ">Product Name</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-price">
                    <h6 className="mb-0">Purchase Price</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-price">
                    <h6 className="mb-0">WholeSale Price</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-price">
                    <h6 className="mb-0">Credit Price</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-price">
                    <h6 className="mb-0">Retail Price</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-status">
                    <h6 className="mb-0">
                      Inventory <br />
                      Status
                    </h6>
                  </div>
                  {/* <div className="col-lg-1 col-sm-2 col-4 col-date">
                    <h6 className="mb-0">Created Date</h6>
                  </div> */}
                  <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                    <h6 className="mb-0">Action</h6>
                  </div>
                </div>
              </article>
              {console.log(getProductData?.data, "fgasdj")}
              {getProductData?.data && getProductData?.data.length > 0 ? (
                getProductData?.data.map((product, index) => (
                  <article className="itemlist" key={product.id}>
                    {console.log(product?.image, "dkjfkl")}
                    {/* {console.log(API_BASE + product.image[0], "lkjsflkjsf")} */}
                    <div className="row align-items-center">
                      <div className="col-lg-1 col-sm-4 col-4 flex-grow-1 col-name">
                        <div className="left">
                          {/* {console.log(
                            product?.image.length > 0 ? "00" : "jhj",
                            "3erfghuio"
                          )} */}
                          {/* {product?.image != null && (
                            <SlideshowLightbox className="container d-flex gap-2 mx-auto overflow-hidden">
                              {
                              product?.image != []
                                ? (product?.image?.map((data) => (
                                
                                  
                                    <img
                                      className="w-full rounded"
                                      src={API_BASE + data}
                                      style={{height:"100px"}}
                                      alt="jkkkkk"
                                      // alt={noImg}
                                    />
                                  )))
                                : console.log("qwrtg")}
                            </SlideshowLightbox>
                          )} */}
                          <SlideshowLightbox className="container d-flex gap-2 mx-auto overflow-hidden">
                            {product?.image?.length > 0 ? (
                              product?.image?.map((data) => (
                                <img
                                  className="w-full rounded"
                                  src={API_BASE + data}
                                  style={{
                                    height: "100px",
                                    width: "-webkit-fill-available",
                                  }}
                                  alt="jkkkkk"
                                  // alt={noImg}
                                />

                                // console.log(data,'jhdfhkhd')
                              ))
                            ) : (
                              <img
                                className="w-full rounded"
                                src={noImg}
                                style={{ height: "100px" }}
                                alt="jkkkkk"
                                // alt={noImg}
                              />
                            )}
                          </SlideshowLightbox>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-4 col-8 flex-grow-1 col-name">
                        <a className="itemside" href="#">
                          <div className="info">
                            <h6 className="mb-0">{product.name}</h6>
                            <p className="text-muted">
                              {product.category.name}
                            </p>
                          </div>
                        </a>
                      </div>

                      <div className="col-lg-1 col-sm-2 col-4 col-price">
                        <span>₹{product?.p_rate}</span>
                      </div>

                      <div className="col-lg-1 col-sm-2 col-4 col-price">
                        <span>₹{product?.w_rate}</span>
                      </div>
                      <div className="col-lg-1 col-sm-2 col-4 col-price">
                        <span>₹{product?.c_rate}</span>
                      </div>

                      <div className="col-lg-1 col-sm-2 col-4 col-price">
                        <span>₹{product?.msrp}</span>
                      </div>

                      <div className="col-lg-1 col-sm-2 col-4 col-status">
                        <span
                          className={`badge rounded-pill alert-${
                            product.product_inventory[0]?.current_qty > 0
                              ? "success"
                              : "danger"
                          }`}
                        >
                          {product.product_inventory[0]?.current_qty > 0
                            ? "In Stock - " +
                              product.product_inventory[0]?.current_qty
                            : "Out Of Stock"}
                        </span>
                      </div>

                      {/* <div className="col-lg-1 col-sm-2 col-4 col-date">
                        <span>
                          {new Date(product.created_at).toLocaleDateString()}
                        </span>
                      </div> */}

                      <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                        {screen?.["product-edit"] === "true" && (
                          <button
                            className="btn btn-sm font-sm rounded btn-brand mr-5"
                            onClick={() =>
                              navigate(`/product/edit/${product?.id}`)
                            }
                          >
                            <i className="material-icons md-edit"></i> Edit
                          </button>
                        )}
                        {screen?.["product-delete"] === "true" && (
                          <button
                            className="btn btn-sm font-sm btn-light rounded"
                            onClick={() => handleDelete(product.id)}
                          >
                            <i className="material-icons md-delete_forever"></i>{" "}
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </article>
                ))
              ) : (
                <p>No products found</p>
              )}
            </div>
          </div>
          <div className="pagination-area mt-15 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li className={`page-item a ${page === 1 ? "disabled" : ""}`}>
                  <a
                    className="page-link page-prev"
                    onClick={() => onPageChange(1)}
                  >
                    First
                  </a>
                </li>
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {[...Array(totalPages1)]
                  ?.map((_, index) => index + 1)
                  .filter((page) => {
                    const startPage = Math.max(1, currentPage - 2);
                    const endPage = Math.min(totalPages1, currentPage + 2);
                    return page >= startPage && page <= endPage;
                  })
                  .map((page) => (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      }`}
                      onClick={() => handlePageChange(page)}
                    >
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                  ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages1 ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
                <li
                  className={`page-item ${
                    page === totalPages1 ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link page-next"
                    onClick={() => onPageChange(totalPages1)}
                  >
                    Last
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageproductslist;
