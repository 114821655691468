import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  InventryCreateValidator,
  InventryCreateValidator2,
} from "../../validators/Validators";
import { useDispatch, useSelector } from "react-redux";
import { productSelector } from "../../store/product/product.model";
import {
  getAllProductPriceRequest,
  getProductRequest,
} from "../../store/product/product.action";
import { selectDefault } from "../../utils/Constants";
import { InventrySelector } from "../../store/InventryScreen/Selector";
import { Table } from "react-bootstrap";
import dayjs from "dayjs";
import {
  getAllInventryDataRequest,
  getInventryDataRequest,
  inventyStateReset,
} from "../../store/InventryScreen/Action";
import { createInventryPayload, createOrderPayload } from "../../utils/config";
import * as XLSX from "xlsx";
function ReportInventory() {
  const dispatch = useDispatch();
  const { getInventData, getAllInventData } = useSelector(InventrySelector);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(InventryCreateValidator2),
  });
  const { getProductData } = useSelector(productSelector);
  console.log(getProductData, "qqq");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const totalPages1 = Math.ceil(getInventData.length / rowsPerPage);
  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const onPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages1) {
      setPage(newPage);
    }
  };
  const handleDownload = () => {
    console.log(getAllInventData, "getAllInventData");
    const worksheet = XLSX.utils.json_to_sheet(getAllInventData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };
  console.log(getAllInventData, "getAllInventData");
  const filter = (formData) => {
    console.log(formData, "eee");
    const payload1 = createInventryPayload(
      page,
      rowsPerPage,
      "",
      formData?.product_id,
      formData?.current_qty_from,
      formData?.current_qty_to,
      formData?.order_status
    );
    console.log(payload1, "eee");
    dispatch(getInventryDataRequest(payload1));
    const payload = createInventryPayload(
      0,
      0,
      "",
      formData?.product_id,
      formData?.current_qty_from,
      formData?.current_qty_to,
      formData?.order_status
    );
    console.log(payload1, "eee");
    dispatch(getAllInventryDataRequest(payload));
  };
  useEffect(() => {
    const payload = {
      query: "",
      prod_id: 0,
      page: 0,
      limit: 0,
    };
    dispatch(getProductRequest(payload));
    dispatch(inventyStateReset());
  }, []);
  console.log(getInventData, "fgfg");
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Inventory Report</h2>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <form onSubmit={handleSubmit(filter)}>
                <div className="row">
                  <div className="col-3 mt-3">
                    <label className="form-label">Product Name</label>
                    <select
                      {...register("product_id")}
                      className="form-control"
                    >
                      <option value="">SELECT</option>
                      {getProductData?.data?.map((product) => (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                      ))}
                    </select>
                    {errors.product_id && (
                      <p className="text-danger">{errors.product_id.message}</p>
                    )}
                  </div>
                  <div className="col-3 mt-3 row">
                    <div className="col-6">
                      <label>Quantity From</label>
                    </div>
                    <div className="col-6">
                      <label>Quantity To</label>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="number"
                        className="form-control"
                        {...register("current_qty_from")}
                      />
                      {errors.current_qty_from && (
                        <p className="text-danger">
                          {errors.current_qty_from.message}
                        </p>
                      )}
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="number"
                        className="form-control"
                        {...register("current_qty_to")}
                      />
                      {errors.current_qty_to && (
                        <p className="text-danger">
                          {errors.current_qty_to.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-3 mt-3">
                    <label className="form-label">Order Status</label>
                    <select
                      className="form-select"
                      {...register("order_status")}
                    >
                      <option className="option" value={""}>
                        All
                      </option>
                      <option className="option" value={0}>
                        IN STOCK
                      </option>
                      <option className="option" value={1}>
                        OUT OF STOCK
                      </option>
                    </select>
                  </div>
                  <div className="col-3 mt-3 d-flex justify-content-evenly align-items-end">
                    <button
                      title="Filter Data"
                      className="btn btn-md rounded font-sm px-5"
                      type="submit"
                    >
                      Filter
                    </button>
                    <button
                      title="Export Excel"
                      className="btn btn-md rounded font-sm px-5"
                      type="button"
                      onClick={handleDownload}
                    >
                      Export
                    </button>
                  </div>
                </div>
              </form>
            </header>
            <div className="card-body">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Product</th>
                    {/* <th>Opening Qty</th> */}
                    <th>Current Qty</th>
                    <th>Inward Qty</th>
                    <th> stock</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {getInventData &&
                    getInventData.map(
                      (item, index) => (
                        console.log(item, "dfjsjgj"),
                        (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item?.products?.name}</td>
                            {/* <td>{item?.opening_qty}</td> */}
                            <td>{item?.current_qty}</td>
                            <td>{item?.inward_qty}</td>
                            <td>
                              <span
                                className={`badge rounded-pill alert-${
                                  item?.current_qty > 0 ? "success" : "danger"
                                }`}
                              >
                                {item?.current_qty > 0
                                  ? "In Stock"
                                  : "Out Of Stock"}
                              </span>
                            </td>

                            <td>
                              {dayjs(item?.created_at).format("DD-MM-YY")}
                            </td>
                          </tr>
                        )
                      )
                    )}
                </tbody>
              </Table>
              {/* </div> */}
            </div>
            <div className="pagination-area mt-15 mb-50">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <li className={`page-item a ${page === 1 ? "disabled" : ""}`}>
                    <a
                      className="page-link page-prev"
                      onClick={() => onPageChange(1)}
                    >
                      First
                    </a>
                  </li>
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <i className="material-icons md-chevron_left"></i>
                    </a>
                  </li>

                  {pagesToDisplay.map((page) => {
                    return page != "..." ? (
                      <li
                        key={page}
                        className={`page-item b ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </a>
                      </li>
                    ) : page == "..." ? (
                      <li
                        key={page}
                        className={`page-item ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        <a className="page-link" href="#">
                          {page}
                        </a>
                      </li>
                    ) : (
                      ""
                    );
                  })}

                  <li
                    className={`page-item ${
                      currentPage === totalPages1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <i className="material-icons md-chevron_right"></i>
                    </a>
                  </li>
                  <li
                    className={`page-item ${
                      page === totalPages1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link page-next"
                      onClick={() => onPageChange(totalPages1)}
                    >
                      Last
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
}

export default ReportInventory;
