import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

import {
  dueDayDataRequest,
  getCardDataRequest,
  getPurchseCardDataRequest,
} from "../../store/dashboard/dashboard.action";
import { dashboardSelector } from "../../store/dashboard/dashboard.model";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { orderSelector } from "../../store/order/order.model";
import {
  creditDueDataRequest,
  getUserOrderDataRequest,
  UpdateOrderStatusRequest,
} from "../../store/order/order.action";
import { createOrderPayload, PurchaseReportPayload } from "../../utils/config";
import { statusClasses, statusData } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import { companySelector } from "../../store/PurchaseCompany/pc.model";
import {
  deletePurchaseCompanyDataRequest,
  getPurchaseCompanyDataRequest,
} from "../../store/PurchaseCompany/pc.action";
import { getPurchaseOrderDataRequest } from "../../store/PurchaseOrder/po.action";
import { purchaseOrderSelector } from "../../store/PurchaseOrder/po.model";
function DashboardPurchase() {
  // Selector
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cardData, purchasecardData } = useSelector(dashboardSelector);
  const { creditDueData } = useSelector(orderSelector);
  const { invoiceData } = useSelector(purchaseOrderSelector);
  const { companyData, deletePCDataSuccess } = useSelector(companySelector);

  console.log(cardData, "6789");
  console.log(creditDueData, "creditDueData");
  console.log(invoiceData, "invoiceData");
  console.log(purchasecardData, "purchasecardData");

  //   state
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");

  var today = new Date(),
    toDaydate =
      today.getDate() +
      "." +
      (today.getMonth() + 1) +
      "." +
      today.getFullYear();
  console.log(toDaydate, "0987");


  //   function
  const onStatusChanges = (eve, id) => {
    console.log(eve, id, "evvid");
    const payload = {
      id,
      status: eve,
    };
    dispatch(UpdateOrderStatusRequest(payload));
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  /* useEffect */
  useEffect(() => {
    const today = new Date();
    const date = dayjs(today).format("YYYY-MM-DD");
    const payload = {
      query: searchQuery,
      page: 0,
      limit: rowsPerPage,
      id: "",
      from_date: date,
      // from_date:" 23-10-2024",
      to_date: date,
    };
    dispatch(getPurchaseCompanyDataRequest(payload));
  }, []);
  useEffect(() => {
    const today = new Date();
    const date = dayjs(today).format("YYYY-MM-DD");
    const payload = {
      page: 0,
      limit: rowsPerPage,
      query: searchQuery,
      companyId: "",
      orderStatus: "",
      date_from: date,
      date_to: date,
    };
    let payload1 = PurchaseReportPayload(
      page,
      rowsPerPage,
      searchQuery,
      "",
      "",
      date,
      date
    );

    dispatch(getPurchaseOrderDataRequest(payload1));
  }, [searchQuery, rowsPerPage]);

  useEffect(() => {
    dispatch(getPurchseCardDataRequest());
  }, []);

  console.log(companyData, "asdf");

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Purchase Dashboard</h2>
              <p>Purchase data about your business here</p>
            </div>
          </div>
          <Row>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Purchase Total</h6>
                    <span>{purchasecardData?.totalInvoices}</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Purchase Amount</h6>
                    <span>
                      ₹{parseFloat(purchasecardData?.totalInvoices).toFixed(2)}
                    </span>
                  </div>
                </article>
              </div>
            </Col>

            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Purchase Today</h6>
                    <span>{purchasecardData?.todayInvoices}</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Purchase Amount Today</h6>
                    <span>
                      ₹
                      {parseFloat(
                        purchasecardData?.todayRevenue?.todayRevenue ?? 0
                      ).toFixed(2)}
                    </span>
                  </div>
                </article>
              </div>
            </Col>
          </Row>
          <div className="card">
            <div className="card-header">
              <h4>Purchase List</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Total Amount</th>
                      <th>Payment Status</th>
                      <th>Purchase Status</th>
                      <th>Order Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData?.rows?.map((ele, ind) => {
                      return (
                        <tr>
                          <td width="20%">
                            <a className="itemside" href="#">
                              <div className="info pl-3">
                                <h6 className="mb-0 title">
                                  {ele?.purchasecompany?.company_name}
                                </h6>
                                <small className="text-muted">
                                  Company ID: {ele?.id}
                                </small>
                              </div>
                            </a>
                          </td>
                          <td>{ele.bill_amount}</td>
                          <td>
                            {" "}
                            {
                              <span
                                className={`badge rounded-pill ${
                                  ele?.paid_status === 0
                                    ? "alert-danger"
                                    : ele?.paid_status === 1
                                    ? "alert-success"
                                    : "alert-warning"
                                }`}
                              >
                                {ele?.paid_status === 0
                                  ? "Unpaid"
                                  : ele?.paid_status === 1
                                  ? "Paid"
                                  : "Partially Paid"}
                              </span>
                            }
                          </td>
                          <td>{ele?.status == 0 ? "Initiated" : "Confirm"}</td>
                          <td>
                            <td>
                              {dayjs(ele?.created_at).format("DD-MM-YYYY")}
                            </td>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default DashboardPurchase;
