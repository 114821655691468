import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import Header from "../Header";

import Sidebar from "../Sidebar";

import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";

import dayjs from "dayjs";
import "toastr/build/toastr.min.css";
import { debounce } from "lodash";
import { companySelector } from "../../store/PurchaseCompany/pc.model";
import {
  deletePurchaseCompanyDataRequest,
  getPurchaseCompanyDataRequest,
  pcStateResetRequest,
} from "../../store/PurchaseCompany/pc.action";
import Footer from "../Footer";
import { authSelector } from "../../store/auth/auth.action";
import { userSelector } from "../../store/user/user.action";

const Vendor = () => {
  const { companyData, deletePCDataSuccess } = useSelector(companySelector);
  console.log(companyData, "companySelector");
  // const { screen } = useSelector(authSelector);
  const data = [];
  const dispatch = useDispatch();
  const { userData, screenData } = useSelector(userSelector);
  const screen = screenData?.response?.screen || {};
  console.log(screen, "dshjfhs");
  const navigate = useNavigate();
  /* State */
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState([1,2]);


  const totalPages2 = Math.ceil(companyData?.count / rowsPerPage) || 1;
  const startIndex = (currentPage - 1) * rowsPerPage;
  let endIndex = [currentPage] * rowsPerPage;
  if (endIndex > companyData?.count) {
    endIndex = companyData?.count;
  }
  /* Function */
  const totalPages = Math.ceil(companyData?.count / rowsPerPage) || 1;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleDelete = (id) => {
    dispatch(deletePurchaseCompanyDataRequest(id));
  };
  const paymentVoucherHit = () => {
    const payload = {
      query: searchQuery,
      page: currentPage,
      limit: rowsPerPage,
      id: "",
      status:status
    };
    dispatch(getPurchaseCompanyDataRequest(payload));
  };

  useEffect(() => {
    paymentVoucherHit();
  }, [searchQuery, rowsPerPage,status]);
  useEffect(() => {
    console.log("flkjgjl");

    const payload = {
      query: searchQuery,
      page: currentPage,
      limit: rowsPerPage,
      id: "",
      status:status
    };
    dispatch(getPurchaseCompanyDataRequest(payload));
  }, [currentPage, rowsPerPage]);
  useEffect(() => {
    if (deletePCDataSuccess) {
      const payload = {
        query: searchQuery,
        page: currentPage,
        limit: rowsPerPage,
        id: "",
        status:status
      };
      dispatch(getPurchaseCompanyDataRequest(payload));
    }
  }, [deletePCDataSuccess]);
  console.log(companyData, "companySelector");
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="page-content">
            <div className="content-header">
              <h2 className="content-title">Purchase Company list</h2>
              <div>
                {screen?.["purchaseCompany-add"] && (
                  <button
                    className="btn btn-primary"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/purchanseCompanyList/add");
                      dispatch(pcStateResetRequest());
                    }}
                  >
                    <i className="material-icons md-plus"></i> Create new
                  </button>
                )}
              </div>
            </div>
            <div className="card mb-4">
              <header className="card-header">
                <Row className="gx-3">
                  <Col lg={4} md={6} className="me-auto">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                  <span className="font-md color-gray-900 font-medium border-1-right span">
                      <h6>
                        {" "}
                      {/* {companyData?.count>endIndex ? `Showing ${startIndex}- ${endIndex} of  ${companyData?.count} result`:`Showing ${startIndex}- ${companyData?.count} of ${companyData?.count} result`} */}

                        Showing {startIndex}&ndash;{endIndex} <br /> of{" "}
                        {companyData?.count} results
                      </h6>
                    </span>
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <select className="form-select"  onChange={(e)=>setStatus(e.target.value)}>
                      <option>Status</option>
                      <option value={1}>Active</option>
                    <option value={0}>Disabled</option>
                    <option value={[1,2]}>Show all</option>
                    </select>
                  </Col>
                  <Col lg={2} md={3} xs={6}>
                    <select
                      className="form-select"
                      onChange={(e) => setRowsPerPage(e.target.value)}
                    >
                   <option value={20}>Show 20</option>
                    <option value={30}>Show 30</option>
                    <option value={40}>Show 40</option>
                    </select>
                  </Col>
                </Row>
              </header>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Ph No</th>
                        <th>GST NO</th>
                        <th>Status</th>
                        <th className="text-left"> Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyData?.data?.map((ele, ind) => {
                        return (
                          <tr>
                            <td width="20%">
                              <a className="itemside" href="#">
                                <div className="info pl-3">
                                  <h6 className="mb-0 title">
                                    {ele?.company_name}
                                  </h6>
                                  <small className="text-muted">
                                    Company ID: {ele?.id}
                                  </small>
                                </div>
                              </a>
                            </td>
                            {ele.contact_email ? <td>{ele.contact_email}</td>:<td>-</td>}
                            {/* <td>{ele.contact_email}</td> */}
                            {ele.contact_person ? <td>{ele.contact_person}</td>:<td>-</td>}
                            {/* <td>{ele.contact_person}</td> */}
                            {ele.contact_number ? <td>{ele.contact_number}</td>:<td>-</td>}
                            {/* <td>{ele.contact_number}</td> */}
                            {ele.gst_no ? <td>{ele.gst_no}</td>:<td>-</td>}
                            {/* <td>{ele.gst_no}</td> */}
                            <td>
                              <span className="badge rounded-pill alert-success">
                                {ele?.status == 1 ? "Active" : "Unactive"}
                              </span>
                            </td>
                            <td>
                              {screen?.["purchaseCompany-edit"] && (
                                <button
                                  className="btn btn-sm font-sm rounded btn-brand mr-5"
                                  onClick={() =>
                                    navigate(
                                      `/purchanseCompanyList/edit/${ele?.id}`
                                    )
                                  }
                                >
                                  <i className="material-icons md-edit"></i>{" "}
                                  Edit
                                </button>
                              )}
                              {screen?.["purchaseCompany-delete"] && (
                                <button
                                  className="btn btn-sm font-sm btn-light rounded"
                                  onClick={() => handleDelete(ele?.id)}
                                >
                                  <i className="material-icons md-delete_forever"></i>{" "}
                                  Delete
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="pagination-area mt-15 mb-50">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <i className="material-icons md-chevron_left"></i>
                    </a>
                  </li>

                  {[...Array(totalPages)]
                    ?.map((_, index) => index + 1) 
                    .filter((page) => {
                      
                      const startPage = Math.max(1, currentPage - 2); 
                      const endPage = Math.min(totalPages, currentPage + 2); 
                      return page >= startPage && page <= endPage;
                    })
                    .map((page) => (
                      <li
                        key={page}
                        className={`page-item ${
                          currentPage === page ? "active" : ""
                        }`}
                        onClick={() => handlePageChange(page)}
                      >
                        <a className="page-link" href="#">
                          {page}
                        </a>
                      </li>
                    ))}

                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <i className="material-icons md-chevron_right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default Vendor;
