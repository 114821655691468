import {
  BreadcrumbItem,
  Breadcrumb,
  Container,
  Card,
  Row,
  Col,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Popover, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
//   import {
//     EditVendor,
//     UpdateVendor,
//     clearErrormsg,
//     clearVendorLoadingDatas,
//     clearVendorUpdateLoading,
//     createVendor,
//     vendorData,
//     vendorsSelector,
//   } from "../../store/reducer/VendorReducer";

import Swal from "sweetalert2";
//   import { API_STATUS, paymentTypewithcheck } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-dropdown-select";
//   import {
//     countryList,
//     currencyList,
//     usersSelector,
//   } from "../../store/reducer/UserReducer";
import { vendorCreate } from "../../utils/Validation";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  getPurchaseCompanyDataRequest,
  pcStateResetRequest,
  postPurchaseCompanyDataRequest,
  updatePurchaseCompanyDataRequest,
} from "../../store/PurchaseCompany/pc.action";
import { companySelector } from "../../store/PurchaseCompany/pc.model";
//   import { handleTranslate } from "../../utils/translate";

const AddPruchaseCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id, "ppp");
  const { companyData,postPCDataSuccess,updatePCdataSuccess } = useSelector(companySelector);
  console.log(companyData, "yyy");
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeTab, setActiveTab] = useState("5");
  const [autoGen, setautoGen] = useState(false);
  const [isInActive, setisInActive] = useState(false);
  const [allowCash, setallowCash] = useState(false);
  const [selectedCountryVal, setCountry] = useState([]);
  const [selectedCurrencyVal, setCurrency] = useState([]);
  // const {
  //   error409,
  //   createVendorLoad,
  //   vendorDatas,
  //   editloading,
  //   updateloading,
  //   vendor_details,
  // } = useSelector(vendorsSelector);
  // const { countries, currencies } = useSelector(usersSelector);
  // console.log(countries, updateloading, "6yuhj");
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const open = Boolean(anchorEl);
  const _ = require("lodash");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(vendorCreate),
  });

  console.log(errors, "errors");

  const handleNext = () => {
    setActiveTab("6");
  };

  // const Translate = async (value, field) => {
  //   const translated_data = await handleTranslate(value);
  //   setValue("ar_" + field, translated_data);
  // };
  const onSubmitHandler = (formData) => {
    console.log(formData, "chlk formdata");
    delete formData.vendor_name_arabic;
    delete formData.allow_cash;
    delete formData.auto_gen_gl;
    delete formData.country_name;
    delete formData.currency_type;
    delete formData.is_inactive;
    delete formData.order_amount;
    // formData.order_amount=0
    console.log(formData, "ioishkjk");

    if (!id) dispatch(postPurchaseCompanyDataRequest(formData));
    else dispatch(updatePurchaseCompanyDataRequest({ formData, id }));
    //   if (!vendorId) dispatch(createVendor({ formData }));
    //   else dispatch(UpdateVendor({ formData, vendorId }));
  };

  const handleChange = (event) => {
    setCountry(event);
    //   setCurrency(currencies?.filter((curr) => curr?.id == event[0]?.id));
  };
  const handleCurrencyChange = (event) => {
    //   setCurrency(event);
    //   setCountry(
    //     countries?.filter((country) => country?.id == event[0]?.country_id)
    //   );
  };
  const SelectedCountry = (countrId) => {
    //   return _.filter(countries, ["id", countrId]);
  };
  const SelectedCurrency = (currencyId) => {
    //   return _.filter(currencies, ["id", currencyId]);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    if (id != undefined && id != 0) {
      console.log(id,"Edited");
      const payload = {
        query: searchQuery,
        page: currentPage,
        limit: rowsPerPage,
        status:1,
        id: id,
      };
      dispatch(getPurchaseCompanyDataRequest(payload));
    }
  }, [id]);
console.log(companyData,id,'dgjh');

  useEffect(() => {
    
    if (companyData?.data && (id != undefined && id != 0)) {
      reset({
        contact_person: companyData.data[0]?.contact_person,
        contact_number: companyData.data[0]?.contact_number,
        contact_email: companyData.data[0]?.contact_email,
        gst_no: companyData.data[0]?.gst_no,
        company_name: companyData.data[0]?.company_name,
        address: companyData.data[0]?.address,
        city: companyData.data[0]?.city,
        zipcode: companyData.data[0]?.zipcode,
      });
    }
  }, [companyData?.data]);
  useEffect(()=>{
if(updatePCdataSuccess||postPCDataSuccess){
    navigate("/purchanseCompanyList")
    dispatch(pcStateResetRequest())
}
  },[updatePCdataSuccess, postPCDataSuccess])
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <form onKeyDown={(e) => e.key === "Enter" && e.preventDefault()} onSubmit={handleSubmit(onSubmitHandler)}>
            <Row>
              <Col md={12}>
                <div className="content-header">
                  <h2 className="content-title">
                    {id === 0 || id == undefined
                      ? "Add Purchase Company"
                      : "Edit Purchase Company"}
                    
                  </h2>
                  <div>
                    <button
                      className="btn btn-md rounded font-sm hover-up"
                      type="submit"
                    >
                      Save Company
                    </button>
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="card mb-4">
                  <div className="card-header">
                    <h4>Basic Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label required" htmlFor="name">
                          Company Name
                        </label>
                        <input
                          className="form-control"
                          id="name"
                          type="text"
                          {...register("company_name")}
                          placeholder="Enter your Company name"
                        />
                        {errors?.company_name && (
                          <p className="text-danger">{errors?.company_name?.message}</p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="email"
                          type="email"
                          {...register("contact_email")}
                          placeholder="Enter your contact email"
                        />
                        {errors?.email && (
                          <p className="text-danger">
                            {errors?.email?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="mobile">
                          Contact Person
                        </label>
                        <input
                          className="form-control"
                          id="mobile"
                          type="tel"
                          {...register("contact_person")}
                          placeholder="Enter your contact person name"
                        />
                        {errors?.mobile && (
                          <p className="text-danger">
                            {errors?.mobile?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="password">
                          Contact Number
                        </label>
                        <input
                          className="form-control"
                          id="password"
                          type="text"
                          {...register("contact_number")}
                          placeholder="Enter your contact number"
                        />
                        {errors?.password && (
                          <p className="text-danger">
                            {errors?.password?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="role_id">
                          GST Number
                        </label>
                        <input
                          className="form-control"
                          id="role_id"
                          type="text"
                          {...register("gst_no")}
                          placeholder="Enter GST Number"
                        />
                        {errors?.role_id && (
                          <p className="text-danger">
                            {errors?.role_id?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="city">
                          City
                        </label>
                        <input
                          className="form-control"
                          id="city"
                          type="text"
                          {...register("city")}
                          placeholder="Enter your city"
                        />
                        {errors?.city && (
                          <p className="text-danger">{errors?.city?.message}</p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="zipcode">
                          Zipcode
                        </label>
                        <input
                          className="form-control"
                          id="zipcode"
                          type="text"
                          {...register("zipcode")}
                          placeholder="Enter your zipcode"
                        />
                        {errors?.zipcode && (
                          <p className="text-danger">
                            {errors?.zipcode?.message}
                          </p>
                        )}
                      </div>

                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="address">
                          Address
                        </label>
                        <textarea
                          className="form-control"
                          id="address"
                          {...register("address")}
                          placeholder="Enter your address"
                          rows="3"
                        ></textarea>
                        {errors?.address && (
                          <p className="text-danger">
                            {errors?.address?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </section>
      </main>
    </>
  );
};

export default AddPruchaseCompany;
