import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const getUserOrderDataApi = (query) => {
  console.log(query, "3erftyh");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/orders/list?query=" +
      query.query +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit +
      "&status=" +
      query.status +
      "&customerId=" +
      query.customer +
      "&order_status=" +
      query.order_status +
      "&order_id=" +
      query.order_id +
      "&from_date=" +
      query.from_date +
      "&to_date=" +
      query.to_date,
    Http.getAuthToken()
  );
};

export const getUserCotationDataApi = (query) => {
  console.log(query, "3erftyh");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/orders/cotation?query=" +
      query.query +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit +
      "&customerId=" +
      query.customer +
      "&order_status=" +
      query.order_status +
      "&order_id=" +
      query.order_id +
      "&from_date=" +
      query.from_date +
      "&to_date=" +
      query.to_date,
    Http.getAuthToken()
  );
};
export const getUserCotationDetailsApi = (id) => {

  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/orders/cotationdetials/"+id,
    Http.getAuthToken()
  );
};
export const postOrderDispatchApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/orders/dispatch-status",
    formData,
    Http.getAuthToken()
  );
};
export const postCotationDispatchApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/checkout/cotataionorder",
    formData,
    Http.getAuthToken()
  );
};
export const orderDetailDataApi = (query) => {
  console.log(query, "3erftyh");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/orders/" + query?.id, Http.getAuthToken());
};
export const orderStatusUpdateApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/orders/status", payload, Http.getAuthToken());
};
export const billStatusUpdateApi = (data) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl + "/orders/bill_type/"+data.id,data.payload, Http.getAuthToken());
};
export const crdDueDataApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/orders/reminder", Http.getAuthToken());
};
export const srdDueDataApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/orders/purchasedropreminder", Http.getAuthToken());
};

export const pdfApi = (query) => {
  console.log(query, "valeusqu");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/orders/generate_invoice/" + query,
    Http.getAuthToken()
  );
};
export const EpdfApi = (query) => {
  console.log(query, "valeusqu");
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/orders/generate_estimate/" + query,
    Http.getAuthToken()
  );
};
export const checkOutApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/checkout/admincheckout",
    payload,
    Http.getAuthToken()
  );
};
export const updateApi = (payload) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/checkout/admincheckout/update/&id="+payload.orderId,
    payload,
    Http.getAuthToken()
  );
};

export const getUserByidDetailApi = (query) => {
  console.log(query, "queryvadf")
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/orders/get_orderbyid_details?query=" +
      query.query +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit +
      "&customerId=" +
      query.customerId,
    Http.getAuthToken()
  );
};
export const createPayment = (data) => {
  console.log(data, 'dadfdfta');
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/orders/payment/create", data, Http.getAuthToken());
};

export const getPayments = (query) => {
   console.log(query, "54678")
    const baseUrl = getBaseEndpointUrl();
    return Http.get(baseUrl + "/orders/payment?query=" + query.query + "&page=" + query.page + "&limit=" + query.limit+"&from_date="+query.from_date+"&to_date="+query.to_date, Http.getAuthToken());
};

export const getpaymentStat = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/orders/payment/stat", Http.getAuthToken());
};
export const updateTprice = (data) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl + "/orders/"+data.id,data.payload, Http.getAuthToken());
};

export const weeklypayApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/orders/weeklypayreminder", Http.getAuthToken());
};