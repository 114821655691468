import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { productSelector } from "../../store/product/product.model";
import avatar1 from "../../assets/imgs/people/avatar1.jpg";
import avatar2 from "../../assets/imgs/people/avatar2.jpg";
import avatar3 from "../../assets/imgs/people/avatar3.jpg";
import avatar4 from "../../assets/imgs/people/avatar4.jpg";
import {
  deleteUserDataRequest,
  getUserDataRequest,
  logout,
  userSelector,
} from "../../store/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useState } from "react";
import { authSelector } from "../../store/auth/auth.action";
function PageVendorslist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    userData,
    deleteLoadSuccess,
    createSuccess,
    updateSuccess,
    screenData,
  } = useSelector(userSelector);
  console.log(userData, "userDAa");
  const screen = screenData?.response?.screen || {};
  console.log(userData, "dtytyhdf");
  // const { screen } = useSelector(authSelector);
  const data = [];
  /* State */
  let [page, setPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState([1, 2]);

  // selector
  const { getProductData } = useSelector(productSelector);
  console.log(getProductData, "endindex");
  /* Function */
  const startIndex = (page - 1) * rowsPerPage;
  let endIndex = page * rowsPerPage;
  if (endIndex > userData?.count) {
    endIndex = userData?.count;
  }
  const totalPages = Math.ceil(userData?.count / rowsPerPage) || 1;

  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 =
    currentPage < totalPages
      ? currentPage + 1 < totalPages
        ? currentPage + 1
        : null
      : null;
  const nextPage2 =
    totalPages != currentPage
      ? currentPage < totalPages && currentPage > 3
        ? currentPage + 2 > totalPages
          ? null
          : currentPage + 2
        : null
      : null;
  const lastPage_before =
    currentPage > 3 && totalPages != currentPage
      ? totalPages > currentPage + 2
        ? "..."
        : null
      : null;
  const lastPage =
    currentPage > 3 && totalPages - 1 != currentPage ? totalPages : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  console.log(pagesToDisplay, "jdfkjdsfhkh");

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handleDelete = (id) => {
    dispatch(deleteUserDataRequest(id));
  };
  const onPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };
  console.log(startIndex,endIndex,page *rowsPerPage,'fkgjfll');
  
  /* useEffect */
  useEffect(() => {
    console.log("dfldjfl");

    const payload = {
      query: searchQuery,
      page: currentPage,
      limit: rowsPerPage,
      userId: "",
      role_id: [4],
      status: status,
    };
    dispatch(getUserDataRequest(payload));
  }, [searchQuery, currentPage, rowsPerPage, status]);
  useEffect(() => {
    if (deleteLoadSuccess || createSuccess || updateSuccess) {
      const payload = {
        query: searchQuery,
        page: currentPage,
        limit: rowsPerPage,
        userId: "",
        status: status,
      };
      dispatch(getUserDataRequest(payload));
      dispatch(logout());
    }
  }, [deleteLoadSuccess]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <h2 className="content-title">Vendors list</h2>
            <div>
              {screen?.["vendor-add"] && (
                <button
                  className="btn btn-primary"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/vendor/add");
                    dispatch(logout());
                  }}
                >
                  <i className="material-icons md-plus"></i> Create new
                </button>
              )}
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <Row className="gx-3">
                <Col lg={4} md={6} className="me-auto">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Col>
               
                <Col lg={1} md={3} xs={6}>
                  <span className="font-md color-gray-900 font-medium border-1-right span">
                    <h6>
                      {" "}
                      {/* {userData?.count>endIndex ? `Showing ${startIndex}- ${endIndex} of ${userData?.count} result`:`Showing ${startIndex}- ${userData?.count} of ${userData?.count} result`} */}

                      Showing {startIndex}&ndash;{endIndex} of{" "}
                      {userData?.count} results
                    </h6>
                  </span>
                </Col>
                 <Col lg={2} md={3} xs={6}>
                  <select
                    className="form-select"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option>Status</option>
                    <option value={1}>Active</option>
                    <option value={0}>Disabled</option>
                    <option value={[1, 2]}>Show all</option>
                  </select>
                </Col>  
                <Col lg={2} md={3} xs={6}>
                  <select
                    className="form-select"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value={20}>Show 20</option>
                    <option value={30}>Show 30</option>
                    <option value={40}>Show 40</option>
                  </select>
                </Col>
              </Row>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="col-2">Vendor</th>
                      <th className="col-2">Email</th>
                      <th className="col-2">Status</th>
                      <th className="col-2">Registered</th>
                      <th className="text-left col-2"> Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.data?.map((ele, ind) => {
                      return (
                        <tr>
                          <td width="20%">
                            <a className="itemside" href="#">
                              <div className="info pl-3">
                                <h6 className="mb-0 title">{ele?.name}</h6>
                                <small className="text-muted">
                                  Vendor ID: {ele?.id}
                                </small>
                              </div>
                            </a>
                          </td>
                          <td>{ele.email}</td>
                          <td>
                            <span className="badge rounded-pill alert-success">
                              {ele?.status == 1 ? "Active" : "Unactive"}
                            </span>
                          </td>
                          <td>{dayjs(ele?.created_at).format("DD/MM/YYYY")}</td>
                          <td>
                            {screen?.["vendor-edit"] && (
                              <button
                                className="btn btn-sm font-sm rounded btn-brand mr-5"
                                onClick={() =>
                                  navigate(`/vendor/edit/${ele?.id}`)
                                }
                              >
                                <i className="material-icons md-edit"></i> Edit
                              </button>
                            )}
                            {screen?.["vendor-delete"] && (
                              <button
                                className="btn btn-sm font-sm btn-light rounded"
                                onClick={() => handleDelete(ele?.id)}
                              >
                                <i className="material-icons md-delete_forever"></i>{" "}
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pagination-area mt-15 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li className={`page-item a ${page === 1 ? "disabled" : ""}`}>
                  <a
                    className="page-link page-prev"
                    onClick={() => onPageChange(1)}
                  >
                    First
                  </a>
                </li>
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {[...Array(totalPages)]
                  ?.map((_, index) => index + 1)
                  .filter((page) => {
                    const startPage = Math.max(1, currentPage - 2);
                    const endPage = Math.min(totalPages, currentPage + 2);
                    return page >= startPage && page <= endPage;
                  })
                  .map((page) => (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      }`}
                      onClick={() => handlePageChange(page)}
                    >
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                  ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
                <li
                  className={`page-item ${
                    page === totalPages ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link page-next"
                    onClick={() => onPageChange(totalPages)}
                  >
                    Last
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default PageVendorslist;
