import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useEffect, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { productSelector } from "../../store/product/product.model";
import dayjs from "dayjs";
import { CategoryAddListValiator } from "../../validators/Validators";
import {
  getCatRequest,
  postCatRequest,
  updateCatRequest,
  deleteCatRequest,
} from "../../store/category/category.action";
import { CatAddSelector } from "../../store/category/category.model";
import { authSelector } from "../../store/auth/auth.action";
import { userSelector } from "../../store/user/user.action";
import { API_BASE } from "../../services/config";
import noimg from "../../assets/imgs/no-image.jpg";

function Pagecategories() {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(CategoryAddListValiator),
  });

  // Selectors
  const { getCatData, sendSuccess, catUpdatedSuccess, catDeleteSuccess } =
    useSelector(CatAddSelector);
  const { getProductData, productDeleteSucces, cat, productBulkSendSuccess } =
    useSelector(productSelector);

  // const { screen } = useSelector(authSelector);
  const { screenData } = useSelector(userSelector);

  // const data=screen
  const screen = screenData?.response?.screen || {};
  console.log(screen, "jdf");

  // States
  const [id, setId] = useState(0);

  const [page, setPage] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [catImage, setCatImage] = useState();

  // page count
  const startIndex = (page - 1) * rowsPerPage;
  let endIndex = [page] * rowsPerPage;
  if (endIndex > getCatData?.count) {
    endIndex = getCatData?.count;
  }
  /* Function */
  const totalPages1 = Math.ceil(getCatData?.count / rowsPerPage) || 1;
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const onSubmitHandler = (formData) => {
    formData.image = catImage;
    if (id === 0) {
      dispatch(postCatRequest(formData));
    } else {
      dispatch(updateCatRequest({ formData, id }));
      setId(0);
    }
  };
  const onPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages1) {
      setPage(newPage);
    }
  };
  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1 && currentPage > 3
        ? currentPage + 2
        : null
      : null;
  const lastPage_before =
    currentPage > 3 && totalPages1 != currentPage ? "..." : null;
  const lastPage =
    currentPage > 3 && totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);

  const handleEdit = (cat) => {
    window.location= "#";
    setId(cat.id);
    reset({
      name: cat.name,
      description: cat.description,
    });
    setCatImage(cat?.image);
  };
  const [imgsrc, setImgsrc] = useState();
  const changeImage = (e) => {
    setImgsrc(e.target.value);
    const file = e.target.files[0];

    setCatImage(file);
  };

  const handleDelete = (id) => {
    dispatch(deleteCatRequest(id));
  };

  useEffect(() => {
    const payload = {
      query: searchQuery,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getCatRequest(payload));
  }, [searchQuery, currentPage, rowsPerPage]);

  useEffect(() => {
    if (sendSuccess || catUpdatedSuccess || catDeleteSuccess) {
      const payload = {
        query: searchQuery,
        page: currentPage,
        limit: rowsPerPage,
      };
      dispatch(getCatRequest(payload));
      reset({
        description: "",
        name: "",
      });
      setId(0);
      setCatImage();
      setImgsrc("");
    }
  }, [sendSuccess, catUpdatedSuccess, catDeleteSuccess, dispatch, reset]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header row">
            <div className="col-lg-8">
              <h2 className="content-title card-title ">Categories</h2>
              <p>Add, edit or delete a category</p>
            </div>
            <div className="col-lg-2  text-center">
              <span className="font-md color-gray-900 font-medium border-1-right span">
                <h6>
                  {" "}
                  {/* {getCatData?.count>endIndex ? `Showing ${startIndex}- ${endIndex} of ${getCatData?.count} result`:`Showing ${startIndex}- ${getCatData?.count} of ${getCatData?.count} result`} */}
                  
                  Showing {startIndex}&ndash;{endIndex} <br /> of{" "}
                  {getCatData?.count } results
                </h6>
              </span>
            </div>
            <div className="col-lg-2">
              <input
                className="form-control bg-white"
                type="text"
                placeholder="Search. . ."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={3}>
                  {/* Form for adding/updating categories */}
                  <form
                    onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                    onSubmit={handleSubmit(onSubmitHandler)}
                  >
                    <div className="mb-4">
                      <label className="form-label" htmlFor="product_name">
                        Name
                      </label>
                      <input
                        className="form-control"
                        id="product_name"
                        type="text"
                        placeholder="Type here"
                        {...register("name")}
                      />
                      {errors.name && (
                        <p className="text-danger">{errors.name.message}</p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        placeholder="Type here"
                        {...register("description")}
                      ></textarea>
                      {errors.description && (
                        <p className="text-danger">
                          {errors.description.message}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Image</label> <br />
                      {catImage && (
                        <img
                          className="img-xs"
                          src={
                            catImage && typeof catImage === "object"
                              ? URL.createObjectURL(catImage)
                              : API_BASE + catImage
                          }
                          alt="upload image"
                          //  style={{height:"100px",width:"100px"}}
                          width="40"
                          height="40"
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        value={imgsrc}
                        onChange={changeImage}
                      />
                    </div>
                    <div className="d-grid">
                      {/* <button className="btn btn-primary" type="submit">
                        {id === 0 ? "Create category" : "Update category"}
                      </button> */}
                      {id === 0
                        ? screen?.["categories-add"] === "true" && (
                            <button className="btn btn-primary" type="submit">
                              Create category
                            </button>
                          )
                        : screen?.["categories-edit"] === "true" && (
                            <button className="btn btn-primary" type="submit">
                              Update category
                            </button>
                          )}
                    </div>
                  </form>
                </Col>

                <Col md={9}>
                  {/* Category List */}
                  <div className="table-responsive">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th className="col-2 text-center">S.No</th>
                          <th className="col-2">Image</th>
                          <th className="col-2">Name</th>
                          <th className="col-2">Description</th>
                          <th className="col-2">Created At</th>
                          <th className="col-2">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getCatData?.data &&
                          getCatData?.data?.map((ele, ind) => {
                            return (
                              <tr key={ele.id}>
                                <td className="col-2 text-center">{ind + 1}</td>
                                <td className="col-2 ">
                                  <img
                                    src={
                                      ele?.image ? API_BASE + ele?.image : noimg
                                    }
                                    alt="img"
                                    style={{ height: "40px", width: "50px" }}
                                  />
                                </td>
                                <td className="col-2">{ele.name}</td>
                                <td className="col-2">{ele.description}</td>
                                <td className="col-2">
                                  {dayjs(ele.created_at).format("DD-MM-YY")}
                                </td>
                                <td className="col-2">
                                  <div className="dropdown">
                                    <a
                                      className="btn btn-light rounded btn-sm font-sm"
                                      href="#"
                                      data-bs-toggle="dropdown"
                                    >
                                      <i className="material-icons md-more_horiz"></i>
                                    </a>
                                    <div className="dropdown-menu">
                                      {screen?.["categories-edit"] ===
                                        "true" && (
                                        <button
                                          className="dropdown-item"
                                          onClick={() => handleEdit(ele)}
                                        >
                                          Edit
                                        </button>
                                      )}

                                      {screen?.["categories-delete"] ===
                                        "true" && (
                                        <button
                                          className="dropdown-item text-danger"
                                          onClick={() => handleDelete(ele.id)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                  <div className="pagination-area mt-15 mb-50">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li
                          className={`page-item a ${
                            page === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link page-prev"
                            onClick={() => onPageChange(1)}
                          >
                            First
                          </a>
                        </li>
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <i className="material-icons md-chevron_left"></i>
                          </a>
                        </li>

                        {pagesToDisplay.map((page) => {
                          return page != "..." ? (
                            <li
                              key={page}
                              className={`page-item b ${
                                currentPage === page ? "active" : ""
                              }`}
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() => handlePageChange(page)}
                              >
                                {page}
                              </a>
                            </li>
                          ) : page == "..." ? (
                            <li
                              key={page}
                              className={`page-item ${
                                currentPage === page ? "active" : ""
                              }`}
                            >
                              <a className="page-link" href="#">
                                {page}
                              </a>
                            </li>
                          ) : (
                            ""
                          );
                        })}

                        <li
                          className={`page-item ${
                            currentPage === totalPages1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link"
                            href="#"
                            onClick={() => handlePageChange(currentPage + 1)}
                          >
                            <i className="material-icons md-chevron_right"></i>
                          </a>
                        </li>
                        <li
                          className={`page-item ${
                            page === totalPages1 ? "disabled" : ""
                          }`}
                        >
                          <a
                            className="page-link page-next"
                            onClick={() => onPageChange(totalPages1)}
                          >
                            Last
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}

export default Pagecategories;
